class MapMappingData{
    constructor () {

        /** 각도 **/
        this.angle = null

        /** 간격 **/
        this.interval = null

        /** 오버랩 **/
        this.overlab = null

        /** 카메라 타입 **/
        this.cameraType = null

        /** 높이 **/
        this.altitude = null
        
        this.type = Object.freeze({
            angle : "angle",
            interval : "interval",
            overlab : "overlab",
            cameraType : "cameraType",
            altitude : "altitude",
        })
    }
    /**MapMappingData객체에 MapMappingData를 세팅하는것  */
    setData(data){
        this.angle = data.angle
        this.interval = data.interval
        this.overlab = data.overlab
        this.cameraType = data.cameraType
        this.altitude = data.altitude
    }
       /**MapMappingData객체에 apiDATA를 세팅하는것  */
    setApiData(data){
    this.angle = data.angle
    this.interval = data.interval
    this.overlab = data.overLab
    this.cameraType = data.cameraType
    this.altitude = data.altitude
    }

    // history에서 매핑 보여줄때 사용하려고 만든 반찬고같은 애.
    setMissionToMapping(data){
        this.angle = data.info.angle
        this.interval = data.info.interval
        this.overlab = data.info.overLab
        this.cameraType = data.info.cameraType
        this.altitude = data.altitude
    }
    
}

export default MapMappingData;