<template>
  <div id="kakaoMap" ref="kakaoMap"></div>
</template>


<script>
import MapType from "@/components/map/enum/MapType";
import MapDataType from "@/components/map/enum/MapDataType";
import MapUtils from "@/components/common/MapUtils";
import MapMappingData from "@/components/map/data/MapMappingData";
import ID_ICON_DRONE from "@/assets/images/map/ic_map_drone.svg";
import ID_ICON_USER from  "@/assets/images/map/ic_map_user.svg";
import ID_ICON_CONTROLLER from "@/assets/images/map/ic_map_controller.svg";
import ID_ICON_STREAMER from "@/assets/images/map/ic_map_streaming.svg";

import ID_ICON_OTHER_USER from "@/assets/images/map/ic_map_user_other.svg";
import ID_ICON_OTHER_CONTROLLER from "@/assets/images/map/ic_map_controller_other.svg";
import ID_ICON_STATION from "@/assets/images/map/ic_map_station.svg";

import ID_ICON_POINT_CAUTION from "@/assets/images/map/ic_map_point_caution.svg";
import ID_ICON_POINT_DANGER from "@/assets/images/map/ic_map_point_danger.svg";
import ID_ICON_POINT_HOME from "@/assets/images/map/ic_map_point_home.svg";
import ID_ICON_POINT_SEARCH from "@/assets/images/map/ic_map_point_search.svg";
import ID_ICON_POINT_TARGET from "@/assets/images/map/ic_map_point_target.svg";
import ID_ICON_POINT_STATION from "@/assets/images/map/ic_map_point_station.svg";
import ID_ICON_POINT_CAUTION_SELECTED from "@/assets/images/map/ic_map_point_caution_selected.svg";
import ID_ICON_POINT_DANGER_SELECTED from "@/assets/images/map/ic_map_point_danger_selected.svg";
import ID_ICON_POINT_HOME_SELECTED from "@/assets/images/map/ic_map_point_home_selected.svg";
import ID_ICON_POINT_SEARCH_SELECTED from "@/assets/images/map/ic_map_point_search_selected.svg";
import ID_ICON_POINT_TARGET_SELECTED from "@/assets/images/map/ic_map_point_target_selected.svg";
import ID_ICON_POINT_STATION_SELECTED from "@/assets/images/map/ic_map_point_station_selected.svg";
import ID_ICON_MAPPING_POINT from "@/assets/images/map/ic_map_mapping_marker.png";
import ID_ICON_WAYPOINT from "@/assets/images/map/ic_map_waypoint_circle.webp";
import ID_ICON_WAYPOINT_SELECTED from "@/assets/images/map/ic_map_waypoint_circle.webp";

import ID_ICON_SITE from "@/assets/images/map/ic_map_site.svg";
import ID_ICON_SITE_SELECTED from "@/assets/images/map/ic_map_site_selected.svg";
import ID_ICON_SITE_CIRCLE from "@/assets/images/map/ic_map_site_circle.svg";

import {getRGBtoHSV} from "@/util/common";
import MapLineData from "@/components/map/data/MapLineData";

import ID_ICON_DRONE_RED from "@/assets/images/map/ic_map_drone_red.svg";
import ID_ICON_DRONE_BLUE from "@/assets/images/map/ic_map_drone_blue.svg";
import ID_ICON_DRONE_ORANGE from "@/assets/images/map/ic_map_drone_orange.svg";
import ID_ICON_DRONE_PURPLE from "@/assets/images/map/ic_map_drone_purple.svg";

let icons = {
  "ID_ICON_DRONE" : {
    icon: ID_ICON_DRONE
  },
  "ID_ICON_USER": {
    icon: ID_ICON_USER
  },
  "ID_ICON_CONTROLLER": {
    icon: ID_ICON_CONTROLLER
  },
  "ID_ICON_STREAMER" : {
    icon : ID_ICON_STREAMER
  },
  "ID_ICON_OTHER_DRONE": {
    icon: ID_ICON_DRONE
  },
  "ID_ICON_OTHER_USER": {
    icon: ID_ICON_OTHER_USER
  },
  "ID_ICON_OTHER_CONTROLLER": {
    icon: ID_ICON_OTHER_CONTROLLER
  },
  "ID_ICON_STATION" : {
    icon: ID_ICON_STATION
  },
  "ID_ICON_POINT_CAUTION": {
    icon: ID_ICON_POINT_CAUTION
  },
  "ID_ICON_POINT_DANGER": {
    icon: ID_ICON_POINT_DANGER
  },
  "ID_ICON_POINT_HOME": {
    icon: ID_ICON_POINT_HOME
  },
  "ID_ICON_POINT_SEARCH": {
    icon: ID_ICON_POINT_SEARCH
  },
  "ID_ICON_POINT_TARGET": {
    icon: ID_ICON_POINT_TARGET
  },
  "ID_ICON_POINT_STATION": {
    icon: ID_ICON_POINT_STATION
  },
  "ID_ICON_POINT_CAUTION_SELECTED": {
    icon: ID_ICON_POINT_CAUTION_SELECTED
  },
  "ID_ICON_POINT_DANGER_SELECTED": {
    icon: ID_ICON_POINT_DANGER_SELECTED
  },
  "ID_ICON_POINT_HOME_SELECTED": {
    icon: ID_ICON_POINT_HOME_SELECTED
  },
  "ID_ICON_POINT_SEARCH_SELECTED": {
    icon: ID_ICON_POINT_SEARCH_SELECTED
  },
  "ID_ICON_POINT_TARGET_SELECTED": {
    icon: ID_ICON_POINT_TARGET_SELECTED
  },
  "ID_ICON_POINT_STATION_SELECTED": {
    icon: ID_ICON_POINT_STATION_SELECTED
  },
  "ID_ICON_WAYPOINT": {
    icon: ID_ICON_WAYPOINT
  },
  "ID_ICON_WAYPOINT_SELECTED": {
    icon: ID_ICON_WAYPOINT_SELECTED
  },
  "ID_ICON_SITE": {
    icon: ID_ICON_SITE
  },
  "ID_ICON_SITE_CIRCLE": {
    icon: ID_ICON_SITE_CIRCLE
  },
  "ID_ICON_SITE_SELECTED": {
    icon: ID_ICON_SITE_SELECTED
  },
  "ID_ICON_MAPPING_POINTT" :{
    icon : ID_ICON_MAPPING_POINT
  },
  "ID_ICON_DRONE_RED" : {
      icon: ID_ICON_DRONE_RED
  },
  "ID_ICON_DRONE_BLUE" : {
      icon: ID_ICON_DRONE_BLUE
  },
  "ID_ICON_DRONE_ORANGE" : {
      icon: ID_ICON_DRONE_ORANGE
  },
  "ID_ICON_DRONE_PURPLE" : {
      icon: ID_ICON_DRONE_PURPLE
  },
};

function convert(deg) {
  return ((deg +360) % 360);
}

// 오버레이로 올릴 이미지의 bounds를 받아 AbstractOverlay를 생성합니다.
function GroundOverlay(markerData, rotate) {
  this.bounds = markerData.liveMapBounds;
  this.rotate = rotate;
  this.imgSrc = markerData.thumbnail;
  this.id = markerData.id;
  var colors = markerData.colors;

  var node = this.node = document.createElement('div');
  node.className = 'node';
  var nodeImg = this.img = document.createElement('img');
  nodeImg.setAttribute("id",markerData.id)

  nodeImg.style.position = 'absolute';
  node.appendChild(nodeImg);
  var img = new Image();
  img.setAttribute('crossOrigin', 'anonymous');
  img.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(this, 0, 0);
    var imageData = ctx.getImageData(0,0,this.width, this.height);
    var data = imageData.data;

    if(colors != null && !colors.isDefault) {

      var beforeH = colors.h - 15
      var afterH =  colors.h + 15;

      var beforeL = colors.l - 0.04;
      var afterL =  colors.l + 0.04;

      if(beforeH < 0)
        beforeH = 0;
      if(afterH > 360)
        afterH= 360;
      if(beforeL < 0)
        beforeL = 0;
      if(afterL > 1)
        afterL = 1;

      var length = this.height * this.width * 4;
      for(var index = 0; index < length; index = index + 4) {
        var pixelR = data[index + 0];
        var pixelG = data[index + 1];
        var pixelB = data[index + 2];
        var pixelA = data[index + 3];
        pixelA;

        let hsv = getRGBtoHSV(pixelR, pixelG, pixelB)
        var isPixel = false;

        if(colors.isRGB){
          isPixel = hsv.l >= beforeL && hsv.l <= afterL && hsv.s <= 0.3;
        } else {
          isPixel = hsv.h >= beforeH && hsv.h <= afterH && hsv.l > 0.1 && hsv.s > 0.1;
        }
        if(!isPixel) {
          data[index + 0] = 0;
          data[index + 1] = 0;
          data[index + 2] = 0;
          data[index + 3] = 0;
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);
    nodeImg.src = canvas.toDataURL("image/png");
  };
  img.src = this.imgSrc;
}

export default {
  name: "KakaoMap",
  components: {},
  props: {
    mapType : {
      type: Object,
      default(){
        return MapType.ID_NORMAL
      },
    },
    mapHeight: {
      type: Number,
      default: 300,
    },
    zoom: {
      type: Number,
      default: 14,
    },
    lng: {
      type: Number,
      default: 127.97547912608519,
    },
    lat: {
      type: Number,
      default: 36.09510666745097,
    },
  },
  data() {
    return {
      map: null,
      MapUtils : new MapUtils(),
      mappingData : new MapMappingData(),
      polylineList: [],
      markerList: [],
      polygonList : [], //0은 circlePolygon, 1은 다각형 polygon
      mappingLineList : [] , //mapping 폴리곤 안에 그릴 라인 polyLineList에 넣고 관리하기가 힘들어서 따로 만듬
      isLineShow: false,
      accessKey: "469a7fd4cb1f60077561c136389b0b8c",
      icons : null,

      isMarkerClick: false,

      //웨이포인트 사용 함수
      infoWindow: null,
      clickLatLng: null,
      waypointLineData: null,
      isPolylineClick: false,
      isMarkerMove: false,
      isPolylineOver: false,


      //마커 이동
      startX: null,
      startY: null,
      startOverlayPoint: null,

      //mapping 사용 함수.
      isMappingCircleClick : false,
      isMappingPolyLineClick : false,
      isMappingCreated : false,
      MappingPolyLineClickInfo : null,
      mappingAngle : 0,
      mappingMul : 2.5 ,
      mappingInterval : 10,
      mappingCamera : null,
      mappingGSD : 0,
      mappingDroneHeight : 0,

      droneLiveCountForEachEvent: 0, //각 현장별 실시간 드론 수를 설정하기 위함.
    };
  },
  updated() {
  },
  mounted() {
    this.$nextTick(function () {
        console.log("Kakaomap created $nextTick")
    });

    if (window.kakao && window.kakao.maps) {
      this.initMap();
    } else {
      this.addKakaoMapScript();
    }
  },
  created() {
    this.$emit("ready-map", false);
    this.icons = icons;
    this.prototypeSetGroundOverlay()
  },
  watch : {
    zoom(value){
      this.map.setLevel(value);
    },
  },
  methods: {
    /**
     * 마커 종류가 많아서 필터때려서 원하는 종류만 가져옴
     * @param mapDataType
     * @returns {number}
     */
    getFillterMarker(mapDataType) {
      const filteredMarker = this.markerList.filter((item) => {
        return item.id.startsWith(mapDataType)
      })
      return filteredMarker
    },

    onSetDraggable(draggable) {
    // 마우스 드래그로 지도 이동 가능여부를 설정합니다
      this.map.setDraggable(draggable);
    },
    //이미지 타입에 따른 클래스 이름 가져오기
    setLayout(){
      this.map.relayout();
    },
    setNotZoomable(){
      this.map.setZoomable(false);
    },
    //#region 카카오맵 초기화 함수
    addKakaoMapScript() {
      const script = document.createElement("script");
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src = "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=" + this.accessKey;
      document.head.appendChild(script);
    },
    initMap() {
      var container = this.$refs.kakaoMap
      // var container = document.getElementById("kakaoMap"); //지도를 담을 영역의 DOM 레퍼런스
      var options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: new kakao.maps.LatLng(this.lat, this.lng), //지도의 중심좌표.
        level: this.zoom //지도의 레벨(확대, 축소 정도)
      };

      this.map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
      this.map.relayout();

      this.onMapTypeChange(this.mapType);
      this.$emit("ready-map", true);
      // 마커 클러스터러를 생성합니다
    },
    //#endregion 카카오맵 초기화 함수

    /**
     * 맵 타입 변경
     * @param type ROADMAP SKYVIEW HYBRID
     */
    onMapTypeChange(type) {
      switch (type.kakaoTypeId) {
        case "ROADMAP":
          this.map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP)
          break;
        case "HYBRID":
          this.map.setMapTypeId(kakao.maps.MapTypeId.HYBRID)
          break;
        case "SKYVIEW":
          this.map.setMapTypeId(kakao.maps.MapTypeId.SKYVIEW)
          break;
      }
    },

    onGetCenterPosition() {
      let position = this.map.getCenter();
      let result = {
        lat: position.getLat(),
        lng: position.getLng(),
      }
      return result;
    },
    onSetUserCenterPosition(position) {
      this.map.setCenter(new kakao.maps.LatLng(position.lat, position.lng));
    },
    onSetCenterPosition(position) {
      this.map.setCenter(new kakao.maps.LatLng(position.lat, position.lng));
    },
    //#region 마커 관리 함수
    /**
     * zIndex : user - 1000, controller - 2000, drone - 3000, point - 4000, event center - 9000, detail - 8000
     * @param markerData 마커 데이터
     * @returns {number}
     */
    setMarkerZIndex(markerData) {
      var zIndex = 0;
      var count = this.markerList.filter((item) => item.mapDataType == markerData.mapDataType).length;
      switch(markerData.mapDataType){
        case MapDataType.ID_LIVE_MAP :
          zIndex = 1000;
          break;
        case MapDataType.ID_POINT:
          zIndex = 1000;
          break;
        case MapDataType.ID_ICON_STREAMER:
        case MapDataType.ID_USER:
          zIndex = 2000;
          break;

        case MapDataType.ID_CONTROLLER:
          zIndex = 3000;
          break;
        case MapDataType.ID_DRONE:
          zIndex = 4000;
          break;
        case MapDataType.ID_PLAYER_MARKER:
          zIndex = 5000;
          break;
        case MapDataType.ID_SITE:
          zIndex = 9000;
          break;
        default: {
          zIndex = 0;
        }
      }
      if(markerData.isMain) {
        zIndex = 8000;
      }
      zIndex = zIndex + count;
      return zIndex;
    },

    /**
     * 마커 이벤트 연결
     * @param customContentHtml
     * @param customOverlay
     */
    setMarkerToEvent(customContentHtml, customOverlay){
      if(this.isMarkerClick == true) {
        customOverlay.clickCall = this.onMarkerMouseClick.bind(this, customOverlay);
        this.markerAddEventHandle(customContentHtml, 'click', customOverlay.clickCall);
      }

      if (this.isMarkerMove == true) {
        // 커스텀 오버레이에 mousedown이벤트를 등록합니다
        customOverlay.mousedownCall = this.onMarkerMouseDown.bind(this, customOverlay);
        customOverlay.mouseupCall = this.onMarkerMouseUp.bind(this, customOverlay);

        this.markerAddEventHandle(customContentHtml, 'mousedown', customOverlay.mousedownCall);

        // mouseup 이벤트가 일어났을때 mousemove 이벤트를 제거하기 위해
        // document에 mouseup 이벤트를 등록합니다
        this.markerAddEventHandle(customContentHtml, 'mouseup', customOverlay.mouseupCall);
      }
    },
    /**
     * 마커 z index 설정
     * @param customContentHtml
     * @param customOverlay
     */
    setMarkerToZIndex(customOverlay, markerData){
      if(markerData.mapDataType != undefined && markerData.mapDataType != null) {
        customOverlay.mapDataType = markerData.mapDataType;
        var zIndex = this.setMarkerZIndex(markerData);
        if(markerData.mapDataType == MapDataType.ID_THUMBNAIL) {
          zIndex = 1000 - this.markerList.length;
        }

        if(markerData.isMain) {
          zIndex = 8999;
        }

        if(markerData.mapDataType != MapDataType.ID_LIVE_MAP ) {
          customOverlay.setZIndex(zIndex);
        }
      }
    },

    /**
     * 마커 생성
     * @param markerData 마커 데이터
     */
    onCreateMarker(markerData) {
      let overlay = null;
      if(markerData.mapDataType == MapDataType.ID_LIVE_MAP) {
        overlay = this.onCreateGroundOverlay(markerData);
        overlay.setMap(this.map);
        overlay.mapDataType = MapDataType.ID_LIVE_MAP;
      }
      if(markerData.mapDataType != MapDataType.ID_LIVE_MAP) {
        let customContent = markerData.html;
        let yAnchor = 1.0;
        if(markerData.mapDataType == MapDataType.ID_PLAYER_MARKER || markerData.mapDataType == MapDataType.ID_DRONE ||markerData.mapDataType == MapDataType.ID_MAPPING_MARKER ) {
          yAnchor = 0.5
        }

        let customOverlay = new kakao.maps.CustomOverlay({
          map: this.map,
          xAnchor: 0.5,
          yAnchor: yAnchor,
          position: new kakao.maps.LatLng(markerData.lat, markerData.lng),
          content: customContent
        });

        if (markerData.isShow != undefined) {
          customOverlay.setVisible(markerData.isShow);
        }

        // 웨이랑 매핑데이터만 클릭이벤트 추가
        if( markerData.mapDataType == MapDataType.ID_WAYPOINT || markerData.mapDataType == MapDataType.ID_MAPPING_MARKER ){
          this.setMarkerToEvent(customContent, customOverlay);
        }

        // customOverlay.setContent(customContent);
        overlay = customOverlay;

        // todo: 제일처음 렌더후,1번 마커 앵커 적용안되서 추가함. 반찬고임. 고치면 삭제 부탁. 원래는 위에 주석처리해둔 로직으로 적용했음.
        setTimeout(() => {
          customOverlay.setContent(customContent);
        }, 0);
      }

      if(overlay != null) {
        overlay.id = markerData.mapId;
        overlay.isMain = markerData.isMain;
        this.setMarkerToZIndex(overlay, markerData);

        this.markerList.push(overlay);

      }
    },
    /**
     * 마커 생성
     * @param markerData 마터 데이터
     */
    onCreateMarkerIndex(markerData, index) {
      var overlay = null;

      if(markerData.mapDataType == MapDataType.ID_LIVE_MAP) {
        overlay = this.onCreateGroundOverlay(markerData);
        overlay.setMap(this.map);
        overlay.mapDataType = MapDataType.ID_LIVE_MAP;
      }

      if(markerData.mapDataType != MapDataType.ID_LIVE_MAP){
        let customContent = markerData.html;
        var xAnchor = 1.0;
        if(markerData.mapDataType == MapDataType.ID_PLAYER_MARKER || markerData.mapDataType == MapDataType.ID_DRONE) {
          xAnchor = 0.5
        }

        let customOverlay = new kakao.maps.CustomOverlay({
          map: this.map,
          position: new kakao.maps.LatLng(markerData.lat, markerData.lng),
          xAnchor: 0.5,
          yAnchor: xAnchor,
        });

        if(markerData.mapDataType != undefined && markerData.mapDataType != null) {
          customOverlay.mapDataType = markerData.mapDataType;
        }

        if (markerData.isShow != undefined) {
          customOverlay.setVisible(markerData.isShow);
        }

        this.setMarkerToEvent(customContent, customOverlay);

        customOverlay.setContent(customContent);
        overlay = customOverlay;
      }

      if(overlay != null) {
        overlay.id = markerData.mapId;
        overlay.isMain = markerData.isMain;
        this.setMarkerToZIndex(overlay, markerData);
      }

      const findeIndex = this.markerList.findIndex((marker)=> {
        return marker.id === `${markerData.mapDataType}_${index}`
      })

      this.markerList.splice(findeIndex+1, 0, overlay);
    },

    onIsMarker(mapId) {
      if (this.markerList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.markerList.findIndex(isFindIndex);
        return index == -1 ? false : true;
      }
      return false;
    },


    /**
     * 마커 제거
     * @param id 해당 마커 아이디
     */
    onDeleteMarkerAt(mapId) {
      if (this.markerList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.markerList.findIndex(isFindIndex);
        if (index != -1) {
          let marker = this.markerList.splice(index, 1)[0];
          marker.setMap(null); // 지도에서 제거한다.
        }
      }
    },

    onDeleteMarkerIndex(markerDataType, index){
      const fillterMarker = this.getFillterMarker(markerDataType)

      const lineType = ()=>{
        if(markerDataType == MapDataType.ID_MAPPING_MARKER) return MapDataType.ID_MAPPING_POLYGON_LINE
        else return markerDataType
      }
      let deleteLine = null;
      let findIndex = this.polylineList.findIndex(line => line.id == `${lineType()}_${(index+1)}`)

      // 라인 삭제하기
      switch(index) {
        case 0:
          if(this.polylineList.length > 0) {
            deleteLine = this.polylineList.splice(findIndex,1);
            deleteLine[0].setMap(null);
          }
          break;
        case (fillterMarker.length-1 ) :
          deleteLine = this.polylineList.splice(findIndex,1);
          deleteLine[0].setMap(null);
          break;
        default :
          deleteLine = this.polylineList.splice(findIndex,1);
          if(deleteLine != null || deleteLine.length > 0) {
            var deletePath = deleteLine[0].getPath();
            var deletePosition = deletePath[deletePath.length - 1];

            findIndex = this.polylineList.findIndex(line => line.id === `${lineType()}_${(index)}`)
            var oldLine = this.polylineList[findIndex];
            var oldPath = oldLine.getPath();

            oldPath[oldPath.length - 1] =  new kakao.maps.LatLng(deletePosition.getLat(), deletePosition.getLng());
            oldLine.setPath(oldPath);
            deleteLine[0].setMap(null);
          }
      }

      // 마커 삭제
      const markerId = markerDataType+"_"+(index+1)
      const deleteMarkerId  = this.markerList.findIndex(item =>{
        return item.id == markerId
      })
      let deleteMarker = this.markerList.splice(deleteMarkerId,1);
      if(deleteMarker != null || deleteMarker.length > 0) {
        deleteMarker[0].setMap(null);
      }
    },
    /**
     * 타입 아이디로 리스트 제거
     * @param id 해당 마커 아이디
     */
    onDeleteMarkerList(markerData) {
      if (this.markerList.length > 0) {
        var filter = this.markerList.filter((item) => item.id.startsWith(markerData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.onDeleteMarkerAt(item.id);
          });
        }
      }
    },

    /**
     * 마커 수정
     * @param markerData 수정 마커 데이터
     */
    onModifyMarkerAt(markerData) {
      if (this.markerList.length > 0) {

        let index = this.markerList.findIndex((element) =>element.id == markerData.mapId);
        if (index != -1) {
          let marker = this.markerList[index];
          marker.isMain = markerData.isMain;
          var zIndex = this.setMarkerZIndex(markerData);
          if(marker.isMain) {
            zIndex = 8999;
          }
          marker.setZIndex(zIndex);

          if (markerData.lat != undefined && markerData.lat != null && markerData.lng != undefined && markerData.lng != null) {
            marker.setPosition(new kakao.maps.LatLng(markerData.lat, markerData.lng))
          }

          if (markerData.isShow != undefined) {
            marker.setVisible(markerData.isShow);
          }
          var modifyContent = markerData.html;
          if(this.isMarkerClick == true) {
            marker.clickCall = this.onMarkerMouseClick.bind(this, marker);
            this.markerAddEventHandle(modifyContent, 'click', marker.clickCall);
          }
          marker.setContent(modifyContent);
        }
      }
    },

    changeMarkerName(doc, name) {
      var findName = doc.getElementById("info-name");
      if(findName != null && findName != null) {
        findName.innerText = name;
      }
    },

    /**
     * 마커 수정
     * @param markerData 수정 마커 데이터
     */
    onModifyMarkerList(markerData) {
      if (this.markerList.length > 0) {
        var filter = this.markerList.filter((item) => item.id.startsWith(markerData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            if (markerData.lat != undefined && markerData.lat != null && markerData.lng != undefined && markerData.lng != null) {
              item.setPosition(new kakao.maps.LatLng(markerData.lat, markerData.lng))
            }

            if (markerData.isShow != undefined) {
              item.setVisible(markerData.isShow);
            }

            if(this.isMarkerClick == true) {
              var parser = new DOMParser();
              var doc = parser.parseFromString(item.getContent().outerHTML, "text/html");
              item.clickCall = this.onMarkerMouseClick.bind(this, item);
              this.markerAddEventHandle(doc.getElementById("info-root"), 'click', item.clickCall);
            }

            var findImg = document.getElementById(item.mapId + "-info-img");
            if (findImg != null && findImg != undefined) {
              if (markerData.imageName != undefined) {
                let imgIcon = this.icons[markerData.imageName].icon
                if(imgIcon != undefined) {
                  findImg.src = imgIcon
                }
                if (markerData.isMain == true) {
                  findImg.classList.add("active");
                } else {
                  findImg.classList.remove('active');
                }
              }
              if (markerData.rotate != undefined) {
                findImg.style.transform = 'rotate(' + markerData.rotate + 'deg)';
              }
            }
          });
        }
      }
    },

    onSetIsMarkerMove() {
      this.isMarkerMove = true;
    },
    onSetIsMarkerClick() {
      this.isMarkerClick = true;
    },

    onInfoMarkerShow(dataType, markerData){
      var customOverlay = null;
      var xAnchor = 0.435;
      var yAnchor = 1.2;

      switch (dataType) {
        case MapDataType.ID_SITE_CIRCLE :
          xAnchor = 0.45;
          yAnchor = 1.2;
          break;
        case MapDataType.ID_POINT:
          xAnchor = 0.5;
          yAnchor = 1.35;
          break;
        case MapDataType.ID_MAP_INFO_USER:
          xAnchor = 0.5;
          yAnchor = 1.2;
          break;
        case MapDataType.ID_MAP_INFO_DRONE:
          xAnchor = 0.5;
          yAnchor = 1.2;
          break;
        case MapDataType.ID_MAP_INFO_POINT:
          xAnchor = 0.47;
          yAnchor = -0.18;
          break;
      }

      if(markerData.html != null) {
        const findIndex = this.markerList.findIndex((item) => item.id == markerData.mapId);
        if (findIndex != -1) {
          customOverlay = this.markerList[findIndex];
          customOverlay.setPosition(new kakao.maps.LatLng(markerData.lat, markerData.lng));
        } else {
          customOverlay = new kakao.maps.CustomOverlay({
            map: this.map,
            xAnchor: xAnchor,
            yAnchor: yAnchor,
          });
          customOverlay.setVisible(true);
          customOverlay.setPosition(new kakao.maps.LatLng(markerData.lat, markerData.lng));
          customOverlay.id = markerData.mapId;
          customOverlay.mapDataType = markerData.mapDataType;
          customOverlay.setZIndex(9999);
          if(dataType === MapDataType.ID_SITE_CIRCLE){
            customOverlay.setZIndex(1999);//종합상황판에서 custom overlay 위에 marker가 오지 않도록..
          }
          this.markerList.push(customOverlay);
        }
        customOverlay.setContent(markerData.html);
      }

    },

    // 커스텀 오버레이에 mousedown 했을 때 호출되는 핸들러 입니다
    onMarkerMouseClick(customOverlay, e) {
      // 커스텀 오버레이를 드래그 할 때, 내부 텍스트가 영역 선택되는 현상을 막아줍니다.
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }
      this.$emit('item-click',customOverlay.id.replace(`${customOverlay.mapDataType}_` ,''), customOverlay.mapDataType, customOverlay.isMain)
    },

    // 커스텀 오버레이에 mousedown 했을 때 호출되는 핸들러 입니다
    onMarkerMouseDown(customOverlay, e) {
      // 커스텀 오버레이를 드래그 할 때, 내부 텍스트가 영역 선택되는 현상을 막아줍니다.
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }

      var proj = this.map.getProjection(), // 지도 객체로 부터 화면픽셀좌표, 지도좌표간 변환을 위한 MapProjection 객체를 얻어옵니다
          overlayPos = customOverlay.getPosition(); // 커스텀 오버레이의 현재 위치를 가져옵니다

      // 커스텀오버레이에서 마우스 관련 이벤트가 발생해도 지도가 움직이지 않도록 합니다
      kakao.maps.event.preventMap();

      // mousedown된 좌표를 설정합니다
      this.startX = e.clientX;
      this.startY = e.clientY;

      // mousedown됐을 때의 커스텀 오버레이의 좌표를
      // 지도 컨테이너내 픽셀 좌표로 변환합니다
      this.startOverlayPoint = proj.containerPointFromCoords(overlayPos);

      // document에 mousemove 이벤트를 등록합니다
      customOverlay.mousemoveCall = this.onMarkerMouseMove.bind(this, customOverlay);

      this.markerAddEventHandle(document, 'mousemove', customOverlay.mousemoveCall);
    },

    // 커스텀 오버레이에 mousedown 한 상태에서
    // mousemove 하면 호출되는 핸들러 입니다
    onMarkerMouseMove(customOverlay, e) {
      // 커스텀 오버레이를 드래그 할 때, 내부 텍스트가 영역 선택되는 현상을 막아줍니다.
      if (e.preventDefault) {
        e.preventDefault();
      } else {
        e.returnValue = false;
      }

      const proj = this.map.getProjection()// 지도 객체로 부터 화면픽셀좌표, 지도좌표간 변환을 위한 MapProjection 객체를 얻어옵니다
      const deltaX = this.startX - e.clientX // mousedown한 픽셀좌표에서 mousemove한 좌표를 빼서 실제로 마우스가 이동된 픽셀좌표를 구합니다
      const deltaY = this.startY - e.clientY

      // mousedown됐을 때의 커스텀 오버레이의 좌표에 실제로 마우스가 이동된 픽셀좌표를 반영합니다
      const newPoint = new kakao.maps.Point(this.startOverlayPoint.x - deltaX, this.startOverlayPoint.y - deltaY)

      // 계산된 픽셀 좌표를 지도 컨테이너에 해당하는 지도 좌표로 변경합니다
      const newPos = proj.coordsFromContainerPoint(newPoint);
      let nextLineIndex = null
      let nextLine = null
      let nextPath = null

      const filterMarker = this.getFillterMarker(customOverlay.mapDataType)
      const filterMarkerSize = this.getFillterMarker(customOverlay.mapDataType).length

      if (this.isMarkerMove) {
        let index = customOverlay.id.replace(customOverlay.mapDataType + "_", "") - 1;
        this.$emit("waypoint-change", index, {lat: newPos.getLat(), lng: newPos.getLng()});
        this.$emit("mapping-change", index , {lat: newPos.getLat(), lng: newPos.getLng()});

        // 라인이 있을떄.
        if (this.polylineList.length > 0) {
          var currentLineIndex = index - 1;
          var currentLine = null;
          var path = null;

          switch (index) {
            //첫번째 마커 클릭했을때
            case 0 :
              if(customOverlay.mapDataType == "ID_MAPPING_MARKER" && this.polylineList.length>2){
                currentLine = this.polylineList[0];
                path = currentLine.getPath();
                path[0] = newPos;
                currentLine.setPath(path);

                nextLineIndex = this.polylineList.length-1
                nextLine = this.polylineList[nextLineIndex]
                nextPath = nextLine.getPath();
                nextPath[0] = newPos;
                nextLine.setPath(nextPath);
              } else { //MapDataType.ID_WAYPOINT
                currentLine = this.polylineList[0];
                path = currentLine.getPath();
                path[0] = newPos;
                currentLine.setPath(path);
              }
              break;
            case filterMarkerSize - 1: // 마지막 마커 움직였을때
              if(customOverlay.mapDataType === "ID_MAPPING_MARKER" && this.polylineList.length>2){
                  var currentIndex = filterMarker.length - 1
                  currentLine = this.polylineList[currentIndex-1];
                  path = currentLine.getPath();
                  path[1] = newPos;
                  currentLine.setPath(path);

                  nextLineIndex = this.polylineList.length-1
                  nextLine = this.polylineList[nextLineIndex]
                  nextPath = nextLine.getPath();
                  nextPath[1] = newPos;
                  nextLine.setPath(nextPath);
              }else{
                currentLine = this.polylineList[this.polylineList.length - 1];
                path = currentLine.getPath();
                path[1] = newPos;
                currentLine.setPath(path);
              }

              break;
            default : // 중간에 마커 움직였을때
              currentLine = this.polylineList[currentLineIndex];
              path = currentLine.getPath();
              path[1] = newPos;
              currentLine.setPath(path);

              nextLineIndex = currentLineIndex + 1;
              nextLine = this.polylineList[nextLineIndex];
              nextPath = nextLine.getPath();
              nextPath[0] = newPos;
              nextLine.setPath(nextPath);
          }
        }
        // 커스텀 오버레이의 좌표를 설정합니다
        customOverlay.setPosition(newPos);
        if (this.infoWindow != null) {
          this.infoWindow.setPosition(new kakao.maps.LatLng(newPos.getLat(), newPos.getLng()));
        }
      }
    },

    // mouseup 했을 때 호출되는 핸들러 입니다
    onMarkerMouseUp(customOverlay) {
      // 등록된 mousemove 이벤트 핸들러를 제거합니다
      this.startOverlayPoint = null;
      this.markerRemoveEventHandle(document, 'mousemove', customOverlay.mousemoveCall);
    },

    // target node에 이벤트 핸들러를 등록하는 함수힙니다
    markerAddEventHandle(target, type, callback) {
      if (target.addEventListener) {
        target.addEventListener(type, callback, true);
      } else {
        target.attachEvent('on' + type, callback, true);
      }
    },

    // target node에 등록된 이벤트 핸들러를 제거하는 함수힙니다
    markerRemoveEventHandle(target, type, callback) {
      if (target.removeEventListener) {
        target.removeEventListener(type, callback, true);
      } else {
        target.detachEvent('on' + type, callback, true);
      }
    },

    prototypeSetGroundOverlay(){
      GroundOverlay.prototype = new kakao.maps.AbstractOverlay;
      GroundOverlay.prototype.onAdd = function() {
        var panel = this.getPanels().overlayLayer;
        panel.appendChild(this.node);
      };
      GroundOverlay.prototype.changeColor = function(colors) {
        var nodeImg = this.img;
        var img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0);
          var imageData = ctx.getImageData(0,0,this.width, this.height);
          var data = imageData.data;

          if(colors != null && !colors.isDefault) {

            var beforeH = colors.h - 15
            var afterH =  colors.h + 15;

            var beforeL = colors.l - 0.04;
            var afterL =  colors.l + 0.04;

            if(beforeH < 0)
              beforeH = 0;
            if(afterH > 360)
              afterH= 360;
            if(beforeL < 0)
              beforeL = 0;
            if(afterL > 1)
              afterL = 1;

            var length = this.height * this.width * 4;
            for(var index = 0; index < length; index = index + 4) {
              var pixelR = data[index + 0];
              var pixelG = data[index + 1];
              var pixelB = data[index + 2];
              var pixelA = data[index + 3];
              pixelA;

              let hsv = getRGBtoHSV(pixelR, pixelG, pixelB)
              var isPixel = false;

              if(colors.isRGB){
                isPixel = hsv.l >= beforeL && hsv.l <= afterL && hsv.s <= 0.3;
              } else {
                isPixel = hsv.h >= beforeH && hsv.h <= afterH && hsv.l > 0.1 && hsv.s > 0.1;
              }
              if(!isPixel) {
                data[index + 0] = 0;
                data[index + 1] = 0;
                data[index + 2] = 0;
                data[index + 3] = 0;
              }
            }
          }
          ctx.putImageData(imageData, 0, 0);
          nodeImg.src = canvas.toDataURL("image/png");
        };
        img.src = this.imgSrc;
      }
      // 줌인 아웃 시 이미지를 원하는 bounds값에 맞게 표출하기 위해 img style을 정의 및 선언합니다.
      GroundOverlay.prototype.draw = function() {
        let bounds = this.bounds
        let rotate = this.rotate
        var projection = this.getProjection();

        let rightTop = new kakao.maps.LatLng(bounds.rightTop.lat, bounds.rightTop.lng);
        let leftBottom = new kakao.maps.LatLng(bounds.leftBottom.lat, bounds.leftBottom.lng);
        var ne = projection.pointFromCoords(rightTop);
        var sw = projection.pointFromCoords(leftBottom);

        var width = ne.x - sw.x;
        var height = sw.y - ne.y;

        this.img.style.top = ne.y+'px';
        this.img.style.left = sw.x+'px';
        this.img.style.width = width+'px';
        this.img.style.height = height+'px';

        this.img.style.transform = 'rotate(' + rotate + 'deg)';
      }

      GroundOverlay.prototype.onRemove = function() {
        this.node.parentNode.removeChild(this.node);
      };
    },

    onOverlayColorChange(MapDataType, colors){
      var filter = this.markerList.filter((item)=> item.mapDataType == MapDataType);
      if(filter.length > 0) {
        filter.forEach((item)=> {
          item.changeColor(colors);
        })
      }
    },

    onCreateGroundOverlay(markerData) {
      //마커 이미지 회전 설정
      if(markerData.rotate == undefined || markerData.rotate == null) {
        markerData.rotate = 0;
      }
      var rotate = convert(Number(markerData.rotate));
      let overlay = new GroundOverlay(markerData, rotate);
      return overlay
    },

    distanceBetween(homeLatitude, homeLongitude, aircraftLatitude, aircraftLongitude) {
      // const φ₁ = lat1 * Math.PI / 180;
      // const φ₂ = lat2 * Math.PI / 180;
      // const λ₁ = lon1 * Math.PI / 180;
      // const λ₂ = lon2 * Math.PI / 180;


// 위도, 경도를 라디안 단위로 변환
      const lat1 = homeLatitude * Math.PI / 180;
      const lat2 = aircraftLatitude * Math.PI / 180;
      const lng1 = homeLongitude * Math.PI / 180;
      const lng2 = aircraftLongitude * Math.PI / 180;

      const Bx = Math.cos(lat2) * Math.cos(lng2 - lng1);
      const By = Math.cos(lat2) * Math.sin(lng2 - lng1);
      const lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2),
          Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By));
      const lng3 = lng1 + Math.atan2(By, Math.cos(lat1) + Bx);

      // 라디안을 디그리로 변환
      const valueLat = lat3 * 180 / Math.PI;
      let valueLng = lng3 * 180 / Math.PI;
      var result = {
        lng: valueLng,
        lat: valueLat
      }
      return result;
    },
    onCreateWindowInfoContext(mapDataType){
      var content = null;
      var isRemovable = false;
      var rootDiv = null;

      rootDiv = document.createElement("div");
          rootDiv.className = "buttonWrap";
          rootDiv.style.display = "flex";
          rootDiv.style.marginTop = "20px";
          rootDiv.style.marginBottom = "20px";
          rootDiv.style.justifyContent = "center";
          rootDiv.ondragstart = "return false";

          var regBtn = document.createElement("button");
          regBtn.className = "point medium";
          regBtn.style.width = "80px";
          regBtn.style.marginLeft = "20px";
          rootDiv.appendChild(regBtn);

          var cancelBtn = document.createElement("button");
          cancelBtn.className = "medium margin6";
          cancelBtn.style.width = "80px";
          cancelBtn.style.marginRight = "20px";
          cancelBtn.innerText = this.$t("btn-cancel");

          rootDiv.appendChild(cancelBtn);
          content = rootDiv;
          isRemovable = false;

      switch (mapDataType) {
        case MapDataType.ID_WAYPOINT :
          if (this.isPolylineOver) {
            regBtn.innerText = this.$t("btn-add");
            regBtn.addEventListener("click", this.onWaypointAddBtnEvent, false)
          } else {
            regBtn.innerText = this.$t("btn-register");
            regBtn.addEventListener("click", this.onWaypointCreateBtnEvent, false)
          }
           cancelBtn.addEventListener("click", this.onWaypointCancelBtnEvent, false)
          break;

          case MapDataType.ID_MAPPING :
          if (this.isPolylineOver) {
            regBtn.innerText = this.$t("btn-add");
            regBtn.addEventListener("click", this.onMappingAddBtnEvent, false)
          } else {
            regBtn.innerText = this.$t("btn-register");
            regBtn.addEventListener("click", this.onMappingCreateBtnEvent, false)
          }
          cancelBtn.addEventListener("click", this.onMappingCancelBtnEvent, false)
          break;

      }
      if(this.infoWindow != null) {
        this.infoWindow.close();
        this.infoWindow = null;
      }
      this.infoWindow = new kakao.maps.InfoWindow({
        content: content,
        removable: isRemovable
      });
    },
    onSetIsWindowInfo(mapDataType) {
      var map = this.map;
      var obj = this;
      kakao.maps.event.addListener(map, 'click', function (mouseEvent) {
        if (obj.infoWindow != null) {
          obj.infoWindow.close();
          obj.infoWindow = null;
        }
        var latlng = mouseEvent.latLng;
        obj.clickLatLng = {
          lat: latlng.getLat(),
          lng: latlng.getLng()
        }
        obj.onCreateWindowInfoContext(mapDataType);
        obj.infoWindow.setPosition(new kakao.maps.LatLng(latlng.getLat(), latlng.getLng()));
        obj.infoWindow.open(map,);
      });
    },
    //#endregion

    //#region 웨이포인트 관련 함수
      onWaypointCreateBtnEvent() {
        if (this.clickLatLng != null) {
          this.$emit("waypoint-create", this.clickLatLng);
        }
        this.onWaypointCancelBtnEvent();
      },
      onWaypointAddBtnEvent() {
        if (this.waypointLineData != null) {
          var findIndex = this.waypointLineData.id.replace("ID_WAYPOINT_", "");
          var position1 = this.markerList[findIndex - 1].getPosition();
          var position2 = this.markerList[findIndex].getPosition();
          var result = this.distanceBetween(position1.getLat(), position1.getLng(), position2.getLat(), position2.getLng());
          this.$emit("waypoint-add", findIndex, result);
        }
        this.onWaypointCancelBtnEvent();
      },
      onWaypointDeleteBtnEvent() {
        if (this.clickLatLng != null) {

          var findIndex = -1;
          if (this.polylineList.length > 0) {
            let path = this.polylineList[0].getPath();
            for (var i = 1; path.length; i++) {
              i;
            }
          }
          this.$emit("waypoint-delete", findIndex);
        }
        this.onWaypointCancelBtnEvent();
      },
      onWaypointCancelBtnEvent() {
        if (this.infoWindow != null) {
          this.infoWindow.close()
          this.infoWindow = null;
          this.clickLatLng = null;
          this.waypointLineData = null;
        }
      },

    onWaypointMarkerIdSort() {
      let index = 1;
      this.markerList.forEach((marker) => {
        if(marker.mapDataType === MapDataType.ID_MAPPING || marker.mapDataType === MapDataType.ID_WAYPOINT) {
          let customOverlay = marker
          customOverlay.id = "ID_WAYPOINT_" + index;
          const parser = new DOMParser();
          const doc = parser.parseFromString(customOverlay.getContent().outerHTML, "text/html");
          this.changeMarkerName(doc, index);
          const customContent = doc.getElementById("info-root");


          if (this.isMarkerMove === true) {
            // 커스텀 오버레이에 mousedown이벤트를 등록합니다
            customOverlay.mousedownCall = this.onMarkerMouseDown.bind(this, customOverlay);
            customOverlay.mouseupCall = this.onMarkerMouseUp.bind(this, customOverlay);

            this.markerAddEventHandle(customContent, 'mousedown', customOverlay.mousedownCall);

            // mouseup 이벤트가 일어났을때 mousemove 이벤트를 제거하기 위해
            // document에 mouseup 이벤트를 등록합니다
            this.markerAddEventHandle(customContent, 'mouseup', customOverlay.mouseupCall);
          }

          customOverlay.setContent(customContent);
          index++
        }
      })
      },
      onWaypointLineIdSort(){
        let index = 1
        this.polylineList.forEach(item => {
          if(item.id.startsWith(MapDataType.ID_WAYPOINT)){
            item.id = `${MapDataType.ID_WAYPOINT}_${index}`
            index +=1
          }
        })
      },
      onMappingMarkerIdSort() {
        let index = 1;

        this.markerList.forEach((marker) => {
          if(marker.mapDataType === MapDataType.ID_MAPPING_MARKER) {

            let customOverlay = marker
            customOverlay.id = "ID_MAPPING_MARKER_" + index;
            const parser = new DOMParser();
            const doc = parser.parseFromString(customOverlay.getContent().outerHTML, "text/html");
            this.changeMarkerName(doc, index);
            const customContent = doc.getElementById("info-root");

            if (this.isMarkerMove === true) {
              // 커스텀 오버레이에 mousedown이벤트를 등록합니다
              customOverlay.mousedownCall = this.onMarkerMouseDown.bind(this, customOverlay);
              customOverlay.mouseupCall = this.onMarkerMouseUp.bind(this, customOverlay);

              this.markerAddEventHandle(customContent, 'mousedown', customOverlay.mousedownCall);

              // mouseup 이벤트가 일어났을때 mousemove 이벤트를 제거하기 위해
              // document에 mouseup 이벤트를 등록합니다
              this.markerAddEventHandle(customContent, 'mouseup', customOverlay.mouseupCall);
            }
            customOverlay.setContent(customContent);
            index++
          }
        })
      },

      onMappingLineIdSort(){
        let index = 1
        this.polylineList.forEach(item => {
          if(item.id.startsWith(MapDataType.ID_MAPPING_POLYGON_LINE)){
            item.id = `${MapDataType.ID_MAPPING_POLYGON_LINE}_${index}`
            index +=1
          }
        })
      },
    //#endregion

    //#region 라인 관리 함수
    onSetIsPolylineClick() {
      this.isPolylineClick = true;
    },
    /**
     * 라인 생성
     * @param lineData 라인 새성 데이터
     */
    onCreateLine(lineData) {
      var linePath = [];
      if (lineData.line.length > 0) {
        lineData.line.forEach(item => {
          let latlng = new kakao.maps.LatLng(item.lat, item.lng);
          latlng.id = item.index;
          linePath.push(latlng);
        });

        var opacityValue = (lineData.isShow != undefined && lineData.isShow == true) ? 1 : 0;

        var polyline = new kakao.maps.Polyline({
          map: this.map,
          path: linePath,
          strokeWeight: 5,
          strokeColor: lineData.color,
          strokeOpacity: opacityValue,
        });
        polyline.id = lineData.mapId;
        polyline.sourceColor = lineData.color;
        polyline.linePathLength = linePath.length.toString();
        if (this.isPolylineClick == true) {
          this.lineClickAddListener(polyline);
        }
        this.polylineList.push(polyline);

      }
    },

    /**
     * 라인 생성
     * @param lineData 라인 새성 데이터
     */
    onCreateLineIndex(lineData, index) {
      index

      let linePath = [];

      if (lineData.line.length > 0) {
        lineData.line.forEach(item => {
          let latlng = new kakao.maps.LatLng(item.lat, item.lng);
          latlng.id = item.index;
          linePath.push(latlng);
        });


        var opacityValue = (lineData.isShow != undefined && lineData.isShow == true) ? 1 : 0;

        var polyline = new kakao.maps.Polyline({
          map: this.map,
          path: linePath,
          strokeWeight: 5,
          strokeColor: lineData.color,
          strokeOpacity: opacityValue,
        });
        polyline.id = lineData.mapId;
        polyline.sourceColor = lineData.color;
        polyline.linePathLength = linePath.length.toString();

        if (this.isPolylineClick == true) {
          if(lineData.mapId.startsWith(MapDataType.ID_WAYPOINT) || lineData.mapId.startsWith(MapDataType.ID_MAPPING_POLYGON_LINE)){
            this.lineClickAddListener(polyline);
          }
        }

        let path = this.polylineList[index - 1].getPath();
        let newPath = [];

        for (var i = 0; i < path.length; i++) {
          if (i == 0) {
            var oldPosition = linePath[linePath.length - 1];

            newPath.push(oldPosition);
            continue;
          }
          //
          newPath.push(new kakao.maps.LatLng(path[i].getLat(), path[i].getLng()))
        }

        this.polylineList[index-1].setPath(newPath);
        this.polylineList.splice(index-1 , 0, polyline);
      }
    },


    /**
     * 해당 아이디로 라인 지우기
     * @param id 지우는 라인 아이디
     */
    onDeleteLineAt(mapId) {
      if (this.polylineList.length > 0) {
        let index = this.polylineList.findIndex((element) => {
          return element.id === mapId;
        });

        if (index != -1) {
          let line = this.polylineList[index];
          line.setMap(null); // 지도에서 제거한다.
          this.polylineList.splice(index, 1);
        }
      }
    },

    /**
     * 타입 아이디로 리스트 제거
     * @param id 해당 마커 아이디
     */
    onDeleteLineList(lineData) {
      if (this.polylineList.length > 0) {
        var filter = this.polylineList.filter((item) => item.id.startsWith(lineData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.onDeleteLineAt(item.id);
          });
        }
      }
    },

    /**
     * 라인 전체 사이즈 가져오기
     * @param id 해당 라인 아이디
     * @returns {length} 해당 라인의 전체 갯수
     */
    onGetLineSize(mapId) {
      var size = -1;
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          size = line.getPath().length;
        }
      }
      return size;
    },

    /**
     * 라인 기존에 추가
     * @param lineData 변경할 라인 데이터
     */
    onSetLineAdd(lineData) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == lineData.mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          let path = line.getPath();
          if (lineData.line.length > 0) {
            lineData.line.forEach(item => {
              let latlng = new kakao.maps.LatLng(item.lat, item.lng);
              latlng.id = item.index;
              path.push(latlng);
            });
          }
          line.setPath(path);
          this.lineChangeOption(line, lineData);
        }
      }
    },

    /**
     * 라인 전체 변경
     * @param lineData 변경할 라인 데이터
     */
    onSetLineReplace(lineData) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == lineData.mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          let path = [];
          if (lineData.line.length > 0) {
            lineData.line.forEach(item => {
              let latlng = new kakao.maps.LatLng(item.lat, item.lng);
              path.push(latlng);
            });
          }
          line.setPath(path);
          this.lineChangeOption(line, lineData)
        }
      }
    },

    onModifyLineAt(lineData) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == lineData.mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          this.lineChangeOption(line, lineData);
        }
      }
    },

    onModifyLineList(lineData) {
      if (this.polylineList.length > 0) {
        var filter = this.polylineList.filter((item) => item.id.startsWith(lineData.mapId))
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.lineChangeOption(item, lineData);
          });
        }
      }
    },

    onModifyPolygonList(polygon) {
      if (this.polygonList.length > 0) {
        let filter = this.polygonList.filter((item) => item.mapDataType.startsWith(polygon.mapDataType))

        if (filter.length > 0) {
          filter.forEach((item) => {
            this.polygonChangeOption(item, polygon);
          });
        }
      }
    },

    onIsLine(mapId) {
      if (this.polylineList.length > 0) {
        let findindex = this.polylineList.findIndex((line)=> {
          return line.id === mapId
        });
        return findindex == -1 ? false : true;
      }
      return false;
    },
    /**
     * 현재 라인의 옵션 변경
     * @param mapLine 카카오라인
     * @param lineData 라인데이터
     */
    lineChangeOption(mapLine, lineData) {
      if (lineData.color != undefined) {
        mapLine.setOptions({strokeColor: lineData.color})
      }

      if (lineData.isShow != undefined) {
        var opacityValue = lineData.isShow == true ? 1 : 0;
        mapLine.setOptions({strokeOpacity: opacityValue});
      }

      if (this.isPolylineClick == true) {
        this.lineClickAddListener(mapLine);
      }
    },

    /**
     * 현재 라인의 옵션 변경
     * @param mapPolygon 카카오라인
     * @param polygon 라인데이터
     */
    polygonChangeOption(mapPolygon, polygon) {
      if (polygon.isShow != undefined) {
        var strokeOpacityValue = polygon.isShow == true ? 0.5 : 0;
        var fillOpacityValue = polygon.isShow == true ? 0.2 : 0;

        mapPolygon.setOptions({strokeOpacity: strokeOpacityValue , fillOpacity : fillOpacityValue});
      }
    },

    lineClickAddListener(mapLine) {
      var obj = this;
      // 다각형에 mouseover 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 변경합니다
      // 지역명을 표시하는 커스텀오버레이를 지도위에 표시합니다
      kakao.maps.event.addListener(mapLine, 'mouseover', function () {
        obj.isPolylineOver = true;
        mapLine.setOptions({strokeColor: '#09f'});
      });
      // 다각형에 mouseout 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 원래색으로 변경합니다
      // 커스텀 오버레이를 지도에서 제거합니다
      kakao.maps.event.addListener(mapLine, 'mouseout', function () {
        obj.isPolylineOver = false;
        mapLine.setOptions({strokeColor: mapLine.sourceColor,});
      });

      kakao.maps.event.addListener(mapLine, 'mousedown', function (mouseEvent) {
        obj.waypointLineData = mapLine;
        mouseEvent;
      });
    },
    /**
     * 라인 전체 보이기, 가리기
     * @param isShow true - 보이기, false - 안보이기
     */
    onSetLineAllIsShow(isShow) {
      if (isShow == undefined) {
        return;
      }

      if (this.polylineList.length > 0) {
        var opacityValue = isShow == true ? 1 : 0;
        this.polylineList.forEach(item => {
          item.setOptions({
            strokeOpacity: opacityValue,
          });
        });
      }
    },
    //#endregion

    //#region 공통 함수
      /**
       * 아이디 리스트 외 마커, 라인 제거
       * @param ids 제거하지 않는 아이디
       */
      onLeaveIds(mapDataType, ids) {
        var removeMarkerIds = [];
        var typeMarkerFilter = this.markerList.filter((item) => item.id.startsWith(mapDataType));
        typeMarkerFilter.forEach(item => {
          var filter = ids.filter(id => {
            return item.id == id
          });
          if (filter.length == 0) {
            removeMarkerIds.push(item.id)
          }
        });
        removeMarkerIds.forEach(id => {
          this.onDeleteMarkerAt(id);
        });

        var removeLineIds = [];
        var typeLikeFilter = this.polylineList.filter((item) => item.id.startsWith(mapDataType));
        typeLikeFilter.forEach(item => {
          var filter = ids.filter(id => {
            return item.id == id
          });
          if (filter.length == 0) {
            removeLineIds.push(item.id)
          }
        });
        removeLineIds.forEach(id => {
          this.onDeleteLineAt(id);
        });
      },

      onDeleteAllData() {
        if (this.markerList.length > 0) {
          this.markerList.forEach(item => {
            item.setMap(null); // 지도에서 제거한다.
          });
          this.markerList = [];
        }
        if (this.polylineList.length > 0) {
          this.polylineList.forEach(item => {
            item.setMap(null); // 지도에서 제거한다.
          });
          this.polylineList = [];
        }
        if(this.polygonList.length > 0){
          this.polygonList.forEach(item => {
            item.setMap(null); // 지도에서 제거한다.
          });
          this.polygonList = [];
        }
        if(this.mappingLineList.length>0){
          this.mappingLineList.forEach(item => {
            item.setMap(null)
          })
        }
      },

      createLatLng(lat,lng){
        return new kakao.maps.LatLng(lat, lng);
      },
      //new kakao.maps.LatLng(lat, lng)를 객체형식으로 변환
      setLatLng(latLng){
        return {
          lat : latLng.getLat(),
          lng : latLng.getLng()
        }
      },
    //#endregion

   //#region polygon 관련 함수
    onDeletePolyLineList(deleteData) {
      if (this.polylineList.length > 0) {
        var filter = this.polylineList.filter((item) => item.id.startsWith(deleteData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.onDeletePolyLineAt(item.id);
          });
        }
      }
    },
    onDeletePolyLineAt(mapId) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id === mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index !== -1) {
          let marker = this.polylineList.splice(index, 1)[0];
          marker.setMap(null); // 지도에서 제거한다.
        }
      }
    },


    onDeletePolygonList(deleteData) {
      if (this.polygonList .length > 0) {
        var filter = this.polygonList.filter((item) => item.id.startsWith(deleteData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.onDeletePolygonAt(item.id);
          });
        }
      }
    },
    onDeletePolygonAt(mapId) {
      if (this.polygonList.length > 0) {
        var isFindIndex = (element) => {
          return element.id === mapId;
        };
        let index = this.polygonList.findIndex(isFindIndex);
        if (index !== -1) {
          let marker = this.polygonList.splice(index, 1)[0];
          marker.setMap(null); // 지도에서 제거한다.
        }
      }
    },


    onDeleteMappingLineList(deleteData) {
      if (this.mappingLineList .length > 0) {
        var filter = this.mappingLineList.filter((item) => item.mapId.startsWith(deleteData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.onDeleteMappingLineAt(item.mapId);
          });
        }
      }
    },
    onDeleteMappingLineAt(mapId) {
      if (this.mappingLineList.length > 0) {
        var isFindIndex = (element) => {
          return element.mapId === mapId;
        };

        let index = this.mappingLineList.findIndex(isFindIndex);
        if (index !== -1) {
          let marker = this.mappingLineList.splice(index, 1)[0];
          marker.setMap(null); // 지도에서 제거한다.
        }
      }
    },



    onMappingCreateBtnEvent() { //화면에서 생성 버튼 클릭시 알려주는 애
      if (this.clickLatLng != null) {
        this.$emit("mapping-create", this.clickLatLng);
      }
      this.onMappingCancelBtnEvent();
    },
    onMappingCancelBtnEvent() {
        if (this.infoWindow != null) {
          this.infoWindow.close()
          this.infoWindow = null;
          this.clickLatLng = null;
        }
    },
    onMappingAddBtnEvent(){
      if (this.waypointLineData != null) {
        const findIndex = this.waypointLineData.id.replace("ID_MAPPING_POLYGON_LINE_", "");

        const filterMarker = this.getFillterMarker(MapDataType.ID_MAPPING_MARKER)

        var position1 = filterMarker[findIndex-1].getPosition();
        var position2 = null
        if(findIndex == this.polylineList.length){
          position2 = filterMarker[0].getPosition();
        }else{
          position2 = filterMarker[findIndex].getPosition();
        }
        var result = this.distanceBetween(position1.getLat(), position1.getLng(), position2.getLat(), position2.getLng());
        this.$emit("mapping-add", findIndex, result);
      }
      this.onMappingCancelBtnEvent();
    },
    /**
     * @param polygonData 폴리곤 정보
     */
    onCreatePolygon(polygonData){
      var pathList = [];

      polygonData.posList.forEach(item=>{
        pathList.push(new kakao.maps.LatLng(item.lat, item.lng))
      })
      var polygon = new kakao.maps.Polygon({
          map: this.map,
          path: pathList,
          strokeWeight: polygonData.strokeWeight ,
          strokeColor: polygonData.strokeColor, //선 색상
          strokeOpacity: polygonData.strokeOpacity ,
          strokeStyle: polygonData.strokeStyle , //선 모양
          fillColor: polygonData.fillColor, //채움 색
          fillOpacity: polygonData.fillOpacity,
      });

      polygon.mapId = polygonData.mapId
      polygon.mapDataType = polygonData.mapDataType
      polygon.polygonType = polygonData.polygonType
      polygon.setMap(this.map)
      this.polygonList.push(polygon)
    },

    onIsPolygon(mapId){
      if (this.polygonList.length > 0) {
        const isFindIndex = (polygon) => {
          return polygon.mapId == mapId;
        };
        let index = this.polygonList.findIndex(isFindIndex);

        return index === -1 ? false : true;
      }
      return false;
    },

    setZIndexPolygon(mapId,zIndex){
      const findPolygon = this.polygonList.find((item) => {
        return mapId === item.mapId
      })
      if(findPolygon !== undefined) {
        findPolygon.setZIndex(zIndex)
      }


    },

    onUpdatePolygon(polygonData){
      var pathList = [];
      polygonData.posList.forEach(item=>{
        pathList.push(new kakao.maps.LatLng(item.lat, item.lng))
      })
      //path 수정
       this.polygonList.forEach(item => {
          if(item.mapDataType == polygonData.mapDataType){
            item.setPath(pathList)

          }
       })
    },
    getCircleAndPolygonDistance(polygonType){ //폴리곤 한 점과 circle의 거리 구하기
      var result = null
        var circle = this.polygonList.filter(item =>{
          if(item.polygonType ==  MapDataType.ID_MAPPING_CIRCLE ){
            return item
          }
        })
        for (const item of this.polygonList) {
          if (item.polygonType === polygonType) {
            var path = item.getPath();
              result = this.MapUtils.calculateAngleAndDistance(this.setLatLng(circle[0].getPosition()), this.setLatLng(path[0]));
              result.point = circle[0].getPosition();
          }
        }
        return result

    },
    createPolyLineAt(linePath,polygonData){
        var polyline = new kakao.maps.Polyline({
            map: this.map,
            path: linePath,
            strokeWeight: polygonData.strokeWeight,
            strokeColor: polygonData.color,
            strokeStyle : polygonData.strokeStyle,
            strokeOpacity: polygonData.strokeOpacity,
            draggable : true,
          });
          polyline.mapId = polygonData.polygonType+"_"+polygonData.mapId
          polyline.sourceColor = polygonData.color;
          polyline.polygonType = polygonData.polygonType
          polyline.setMap(this.map)
          if(this.isPolylineClick && polygonData.polygonType == MapDataType.ID_MAPPING_LINE)
            this.onPolyLineAddListner(polyline);
          //this.polylineList.push(polyline);
          this.mappingLineList.push(polyline);
    },

    onPolyLineAddListner(polyline){
      var obj = this;

      kakao.maps.event.addListener(polyline, 'mousedown', function() {
          obj.isMappingPolyLineClick = true;
          obj.polyLineClickId = this.mapId;
          obj.MappingPolyLineClickInfo = this;
          this.setOptions({
             strokeColor: '#008000',
          })
         kakao.maps.event.preventMap();
      });

      kakao.maps.event.addListener(obj.map, 'mouseup', function() {

          obj.MappingPolyLineClickInfo.setOptions({//본래색상으로 되돌리기
             strokeColor: `#31A2FF`,
          })

          obj.isMappingPolyLineClick = false;
          obj.MappingPolyLineClickInfo = null;
          obj.polyLineClickId = null;
         kakao.maps.event.preventMap();
      });

       kakao.maps.event.addListener(polyline, 'mouseover', function() {  //polyline에 마우스를 올릴 경우
          if(!obj.isMappingPolyLineClick){
            this.setOptions({
              strokeColor: '#F10',
            })
          }

      });
      kakao.maps.event.addListener(polyline, 'mouseout', function() {  //polyline에서 마우스가 벗어날 경우
           if(!obj.isMappingPolyLineClick){
            this.setOptions({
              strokeColor: `#31A2FF`,
            })
          }

      });


    },
    setMappingData(value,type){
      type
      this.mappingData.setData(value)
      // if(type == this.mappingData.type.angle){
      //   var circleAndPolygonDistance = this.getCircleAndPolygonDistance(MapDataType.ID_MAPPING_SQURE);
      //   this.onUpdateHiddenPolygon(circleAndPolygonDistance.distance*this.mappingMul )
      // }
    },

    setMappingGSD(){
      var interval = null
      this.deleteMappingLine(MapDataType.ID_MAPPING_POLYLINE)
      if(this.mappingData.altitude == 0 || this.mappingData.altitude == null ||
       this.mappingData.interval == null || this.mappingData.interval == 0 ||
       this.mappingData.cameraType == null )
        return null
      var value = this.mappingData.cameraType;
     // this.mappingGSD = this.MapUtils.calculateGSD(this.mappingDroneHeight*100 ,value.focalLength,value.sensor.height,value.sensor.width,value.aspectRatio.height,value.aspectRatio.width );
     this.mappingGSD = this.MapUtils.calculateGSD(this.mappingData.altitude*100 ,value.focalLength,value.sensor.height,value.sensor.width,value.aspectRatio.height,value.aspectRatio.width );
      console.log("mappingGSD" , this.mappingGSD, this.mappingData)

      interval = this.mappingGSD.aspectRatioWidth * this.mappingGSD.GSDWidth * (1- (this.mappingData.interval / 100))/100
      console.log("mappingGSD2" , this.mappingGSD.aspectRatioWidth,this.mappingGSD.GSDWidth ,this.mappingData.interval, interval)
      return interval
    },
    getPolygonListIndex(id){
      return this.polygonList.findIndex(item =>{return item.polygonType == id})
    },
    deleteMappingLine(type){
      var tempArr = this.mappingLineList.filter(item =>{
        return item.polygonType == type
      })

      tempArr.forEach(tempItem =>{
        var findIndex = this.mappingLineList.findIndex(item =>{
          return item.mapId == tempItem.mapId && item.polygonType == tempItem.polygonType
        })
        if(findIndex != -1){
          let line = this.mappingLineList[findIndex];
           line.setMap(null); // 지도에서 제거한다.
           this.mappingLineList.splice(findIndex, 1);
        }
      })
    },

    getPolyLineAngleAndDistance(PolygonPath, startNumber, endNumber) {
       var startPoint = this.setLatLng(PolygonPath[startNumber]);
       var endPoint = this.setLatLng(PolygonPath[endNumber]);
       return this.MapUtils.calculateAngleAndDistance(startPoint, endPoint)
    },

    //
    onCreateMappingLine(mappingType,polygonType){
      mappingType,polygonType

      var interval = this.setMappingGSD() //계산을 못하는 경우 null이다.
      if(interval == null) return
      this.createMappingLine(interval)
    },

    // 매핑 안에 드론이움직이는 라인
    createMappingLine(lineInterval){
      var findIndex = this.getPolygonListIndex(MapDataType.ID_MAPPING_HIDDEN_POLYGON);
      if(findIndex != -1){
        var hiddenPolygonPath = this.polygonList[findIndex].getPath()

        var nowPoint = 0;
        var nextPoint = 1;
        var prevPoint = 3 ;
        nowPoint , nextPoint , prevPoint,hiddenPolygonPath
        var angle = this.mappingData.angle  % 180
        if(0 <= angle && angle <= 90){
          nowPoint =  0, nextPoint = 1 , prevPoint = 3
        }else if(91 <= angle && angle <= 180 ){
          nowPoint = 1 , nextPoint = 0 , prevPoint = 2
        }

        var zeroToThree = this.getPolyLineAngleAndDistance(hiddenPolygonPath,nowPoint,prevPoint); //0번에서 3번으로 가는거리와 각도
        var startPoint = this.setLatLng(hiddenPolygonPath[nowPoint]) //생성할 라인 시작 점
        var endPoint = this.setLatLng(hiddenPolygonPath[nextPoint]) //생성할 라인 끝점
        var cnt = zeroToThree.distance/lineInterval
        var line = []

        var polygonPath = this.polylineList.filter(item => item.id.startsWith(MapDataType.ID_MAPPING_POLYGON_LINE) )

        for(var i = 0 ; i < cnt ; i++){ //라인 배열 만들기
          var hiddenStartPoint = null;
          var hiddenEndPoint = null;
          var tempArr = []

          hiddenStartPoint = this.MapUtils.calculateNewPosition(startPoint, zeroToThree.angle,   lineInterval * i ,i);
          hiddenEndPoint = this.MapUtils.calculateNewPosition(endPoint, zeroToThree.angle,   lineInterval * i ,i);
            polygonPath.forEach((item , index)=>{
              var next = index+1 >= polygonPath.length ? 0 : index+1
              next

              var start =  this.setLatLng(item.getPath()[0]);
              var end = this.setLatLng(item.getPath()[1])

              var result = this.MapUtils.findIntersection(hiddenStartPoint, hiddenEndPoint, start, end)
              result != null ? tempArr.push(result)  : result
            })
              if(line.length == 0 || tempArr.length == 1 ){ //첫번째 라인 이거나 접점이 1개인 경우
                tempArr.forEach(item=>{
                  line.push( this.createLatLng(item.lat,item.lng))
                })
              }
            else if(tempArr.length > 1) {
              var tempAt1 = tempArr[0];
              var tempAt2 = tempArr[tempArr.length-1];

              //line의 마지막 요소와 tempArr의 좌표중 가장 가까운 애를 찾아서 넣기.
              var lineAt = line[line.length -1];
              var temp1Distance = this.MapUtils.calculateDistance(tempAt1 , this.setLatLng(lineAt));
              var temp2Distance = this.MapUtils.calculateDistance(tempAt2 , this.setLatLng(lineAt));
              if( temp1Distance <= temp2Distance) {
                tempArr.forEach(item=>{
                  line.push( this.createLatLng(item.lat,item.lng))
                })
              } else {
                tempArr.reverse().forEach(item=>{
                  line.push( this.createLatLng(item.lat,item.lng))
                })
              }
            }
        }
         //라인 생성하기
         let mapPolyLine = new MapLineData();
          mapPolyLine.color  = '#00FF91'//`#f6ed99`
          mapPolyLine.line = line
          mapPolyLine.strokeWeight = 5
          mapPolyLine.strokeOpacity = 1
          mapPolyLine.strokeStyle = 'solid'
          mapPolyLine.polygonType = MapDataType.ID_MAPPING_POLYLINE
          mapPolyLine.mapId = "MappingLine"
          this.createPolyLineAt(line,mapPolyLine);

      }
    },
    //#endregion

  },
};
</script>

<style>
.kakao-custom-div > div.showName {
  width: 30px;
  height: 30px;
  transform: translate(0px, 3px);
  text-shadow: 0 0 2px #000;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.kakao-custom-div > div.waypoint {
  position: absolute;
  color: black;
  z-index: 1;
}

.kakao-custom-div > img {
  height: 30px;
  max-height: 30px;
}

.kakao-custom-div > img.active {
  height: 45px;
  max-height: 45px;
}

.kakao-custom-div > div.circle {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  color: white;
}

img.thumbnail_1 {
  width: 168px !important;
  max-width: 168px !important;
  height: 94.5px !important;
  max-height: 94.5px !important;
}

img.thumbnail_1 {
  width: 160px !important;
  max-width: 160px !important;
  height: 90px !important;
  max-height: 90px !important;
}

img.thumbnail_1 {
  width: 160px !important;
  max-width: 160px !important;
  height: 90px !important;
  max-height: 90px !important;
}

img.thumbnail_1 {
  width: 160px !important;
  max-width: 160px !important;
  height: 90px !important;
  max-height: 90px !important;
}
</style>
