const MapKoreaCityPosition = Object.freeze({
  //서울
  ID_SEOUL: {
    point: {
      lat : 37.540705,
      lng : 126.956764,
    },
    name: "ID_SEOUL",
    color: "#00B9AA",
  },

  //인천
  ID_INCHEON: {
    point: {
      lat : 37.469221,
      lng : 126.573234,
    },
    name: "ID_INCHEON",
    color: "#1F48FF",
  },

  //광주
  ID_GWANGJU: {
    point: {
      lat : 35.15972,
      lng : 126.85306
    },
    name: "ID_GWANGJU",
    color: "#FF5300",
  },
  //세종특별시
  ID_SEJONGSI: {
    point: {
      lat : 36.4875,
      lng : 127.28167
    },
    name: "ID_SEJONG",
    color: "#61BFFF",
  },
  //대구광역시
  ID_DAEGU: {
    point: {
      lat : 35.798838,
      lng : 128.583052
    },
    name: "ID_DAEGU",
    color: "#9B00FF",
  },

  //울산
  ID_ULSAN: {
    point: {
      lat : 35.519301,
      lng : 129.239078
    },
    name: "ID_ULSAN",
    color: "#20BE19",
  },
  //대전광역시
  ID_DAEJEON: {
    point: {
      lat : 36.321655,
      lng : 127.378953
    },
    name: "ID_DAEJEON",
    color: "#FF9900",
  },

  //부산광역시
  ID_BUSAN: {
    point: {
      lat : 35.198362,
      lng : 129.053922
    },
    name: "ID_BUSAN",
    color: "#EF2D00",
  },

  //경기도
  ID_GYEONGGIDO: {
    point: {
      lat : 37.567167,
      lng : 127.190292
    },
    name: "ID_GYEONGGIDO",
    color: "#008EFF",
  },
  //강원도
  ID_GANGWONDO: {
    point: {
      lat : 37.555837,
      lng : 128.209315
    },
    name: "ID_GANGWONDO",
    color: "#FF36B2",
  },
  //경상북도
  ID_GYEONGSANGBUKDO: {
    point: {
      lat : 36.248647,
      lng : 128.664734
    },
    name: "ID_GYEONGSANGBUKDO",
    color: "#FFB800",
  },
  //경상남도
  ID_GYEONGSANGNAMDO: {
    point: {
      lat : 35.259787,
      lng : 128.664734
    },
    name: "ID_GYEONGSANGNAMDO",
    color: "#00F467",
  },

  //충청북도
  ID_CHUNGCHEONGBUKDO: {
    point: {
      lat : 36.628503,
      lng : 127.929344
    },
    name: "ID_CHUNGCHEONGBUKDO",
    color: "#DC0097",
  },
  //충청남도
  ID_CHUNGCHEONGNAMDO: {
    point: {
      lat : 36.557229,
      lng : 126.779757
    },
    name: "ID_CHUNGCHEONGNAMDO",
    color: "#DC9016",
  },

  //전라북도
  ID_JEOLLABUKDO: {
    point: {
      lat : 35.716705,
      lng :127.144185
    },
    name: "ID_JEOLLABUKDO",
    color: "#D80000",
  },
  //전라남도
  ID_JEOLLANAMDO: {
    point: {
      lat : 34.8194,
      lng : 126.893113
    },
    name: "ID_JEOLLANAMDO",
    color: "#00C4C0",
  },

  //제주특별자치도
  ID_JEJUDO: {
    point: {
      lat : 33.364805,
      lng : 126.542671
    },
    name: "ID_JEJUDO",
    color: "#6FEB46",
  },
});

export default MapKoreaCityPosition;
