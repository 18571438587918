import ID_ICON_DRONE from "@/assets/images/map/ic_map_drone.svg";
import ID_ICON_USER from  "@/assets/images/map/ic_map_user.svg";
import ID_ICON_CONTROLLER from "@/assets/images/map/ic_map_controller.svg";
import ID_ICON_STREAMER from "@/assets/images/map/ic_map_streaming.svg";

import ID_ICON_OTHER_USER from "@/assets/images/map/ic_map_user_other.svg";
import ID_ICON_OTHER_CONTROLLER from "@/assets/images/map/ic_map_controller_other.svg";
import ID_ICON_STATION from "@/assets/images/map/ic_map_station.svg";

import ID_ICON_POINT_CAUTION from "@/assets/images/map/ic_map_point_caution.svg";
import ID_ICON_POINT_DANGER from "@/assets/images/map/ic_map_point_danger.svg";
import ID_ICON_POINT_HOME from "@/assets/images/map/ic_map_point_home.svg";
import ID_ICON_POINT_SEARCH from "@/assets/images/map/ic_map_point_search.svg";
import ID_ICON_POINT_TARGET from "@/assets/images/map/ic_map_point_target.svg";
import ID_ICON_POINT_STATION from "@/assets/images/map/ic_map_point_station.svg";
import ID_ICON_POINT_CAUTION_SELECTED from "@/assets/images/map/ic_map_point_caution_selected.svg";
import ID_ICON_POINT_DANGER_SELECTED from "@/assets/images/map/ic_map_point_danger_selected.svg";
import ID_ICON_POINT_HOME_SELECTED from "@/assets/images/map/ic_map_point_home_selected.svg";
import ID_ICON_POINT_SEARCH_SELECTED from "@/assets/images/map/ic_map_point_search_selected.svg";
import ID_ICON_POINT_TARGET_SELECTED from "@/assets/images/map/ic_map_point_target_selected.svg";
import ID_ICON_POINT_STATION_SELECTED from "@/assets/images/map/ic_map_point_station_selected.svg";
import ID_ICON_POINT_MAPPING from "@/assets/images/map/ic_map_mapping_marker.png"; 
import ID_ICON_WAYPOINT from "@/assets/images/map/ic_map_waypoint_circle.webp";
import ID_ICON_WAYPOINT_SELECTED from "@/assets/images/map/ic_map_waypoint_circle.webp";

import ID_ICON_SITE from "@/assets/images/map/ic_map_site.svg";
import ID_ICON_SITE_SELECTED from "@/assets/images/map/ic_map_site_selected.svg";
import ID_ICON_SITE_CIRCLE from "@/assets/images/map/ic_map_site_circle.svg";

import ID_ICON_DRONE_RED from "@/assets/images/map/ic_map_drone_red.svg";
import ID_ICON_DRONE_BLUE from "@/assets/images/map/ic_map_drone_blue.svg";
import ID_ICON_DRONE_ORANGE from "@/assets/images/map/ic_map_drone_orange.svg";
import ID_ICON_DRONE_PURPLE from "@/assets/images/map/ic_map_drone_purple.svg";

import ID_SCHEDULE_STATION_GREY from "@/assets/images/map/ic_station_deselect.svg";
import ID_SCHEDULE_STATION_GREEN from "@/assets/images/map/ic_station_select.svg";

import ID_ICON_AI_MARKER from "@/assets/images/map/ic_map_ai_marker.png";

let MapIcon = {
    "ID_ICON_DRONE" : {
        icon: ID_ICON_DRONE
    },
    "ID_ICON_USER": {
        icon: ID_ICON_USER
    },
    "ID_ICON_CONTROLLER": {
        icon: ID_ICON_CONTROLLER
    },
    "ID_ICON_STREAMER" : {
        icon : ID_ICON_STREAMER
    },
    "ID_ICON_OTHER_DRONE": {
        icon: ID_ICON_DRONE
    },
    "ID_ICON_OTHER_USER": {
        icon: ID_ICON_OTHER_USER
    },
    "ID_ICON_OTHER_CONTROLLER": {
        icon: ID_ICON_OTHER_CONTROLLER
    },
    "ID_ICON_STATION" : {
        icon: ID_ICON_STATION
    },
    "ID_ICON_POINT_CAUTION": {
        icon: ID_ICON_POINT_CAUTION
    },
    "ID_ICON_POINT_DANGER": {
        icon: ID_ICON_POINT_DANGER
    },
    "ID_ICON_POINT_HOME": {
        icon: ID_ICON_POINT_HOME
    },
    "ID_ICON_POINT_SEARCH": {
        icon: ID_ICON_POINT_SEARCH
    },
    "ID_ICON_POINT_TARGET": {
        icon: ID_ICON_POINT_TARGET
    },
    "ID_ICON_POINT_STATION": {
        icon: ID_ICON_POINT_STATION
    },
    "ID_ICON_POINT_CAUTION_SELECTED": {
        icon: ID_ICON_POINT_CAUTION_SELECTED
    },
    "ID_ICON_POINT_DANGER_SELECTED": {
        icon: ID_ICON_POINT_DANGER_SELECTED
    },
    "ID_ICON_POINT_HOME_SELECTED": {
        icon: ID_ICON_POINT_HOME_SELECTED
    },
    "ID_ICON_POINT_SEARCH_SELECTED": {
        icon: ID_ICON_POINT_SEARCH_SELECTED
    },
    "ID_ICON_POINT_TARGET_SELECTED": {
        icon: ID_ICON_POINT_TARGET_SELECTED
    },
    "ID_ICON_POINT_STATION_SELECTED": {
        icon: ID_ICON_POINT_STATION_SELECTED
    },
    "ID_ICON_WAYPOINT": {
        icon: ID_ICON_WAYPOINT
    },
    "ID_ICON_WAYPOINT_SELECTED": {
        icon: ID_ICON_WAYPOINT_SELECTED
    },
    "ID_ICON_SITE": {
        icon: ID_ICON_SITE
    },
    "ID_ICON_SITE_CIRCLE": {
        icon: ID_ICON_SITE_CIRCLE
    },
    "ID_ICON_SITE_SELECTED": {
        icon: ID_ICON_SITE_SELECTED
    },
    "ID_ICON_POINT_MAPPING" :{
        icon : ID_ICON_POINT_MAPPING
    },
    "ID_ICON_DRONE_RED" : {
        icon: ID_ICON_DRONE_RED
    },
    "ID_ICON_DRONE_BLUE" : {
        icon: ID_ICON_DRONE_BLUE
    },
    "ID_ICON_DRONE_ORANGE" : {
        icon: ID_ICON_DRONE_ORANGE
    },
    "ID_ICON_DRONE_PURPLE" : {
        icon: ID_ICON_DRONE_PURPLE
    },
    "ID_SCHEDULE_STATION_GREY" : {
        icon: ID_SCHEDULE_STATION_GREY
    },
    "ID_SCHEDULE_STATION_GREEN" : {
        icon: ID_SCHEDULE_STATION_GREEN
    },
    "ID_ICON_AI_MARKER" : {
        icon : ID_ICON_AI_MARKER
    }

};

export default MapIcon;