<template>
  <div id="googleMap" ref="map"></div>
</template>

<script>
import MapType from "@/components/map/enum/MapType";
import MapImageName from "@/components/map/enum/MapImageName";
import MapDataType from "@/components/map/enum/MapDataType";

import ID_ICON_DRONE from "@/assets/images/map/ic_map_drone.svg";
import ID_ICON_USER from  "@/assets/images/map/ic_map_user.svg";
import ID_ICON_CONTROLLER from "@/assets/images/map/ic_map_controller.svg";

import ID_ICON_OTHER_DRONE from "@/assets/images/map/ic_map_drone_other.svg";
import ID_ICON_OTHER_USER from "@/assets/images/map/ic_map_user_other.svg";
import ID_ICON_OTHER_CONTROLLER from "@/assets/images/map/ic_map_controller_other.svg";

import ID_ICON_POINT_CAUTION from "@/assets/images/map/ic_map_point_caution.svg";
import ID_ICON_POINT_DANGER from "@/assets/images/map/ic_map_point_danger.svg";
import ID_ICON_POINT_HOME from "@/assets/images/map/ic_map_point_home.svg";
import ID_ICON_POINT_SEARCH from "@/assets/images/map/ic_map_point_search.svg";
import ID_ICON_POINT_TARGET from "@/assets/images/map/ic_map_point_target.svg";
import ID_ICON_POINT_STATION from "@/assets/images/map/ic_map_point_station.svg";
import ID_ICON_POINT_CAUTION_SELECTED from "@/assets/images/map/ic_map_point_caution_selected.svg";
import ID_ICON_POINT_DANGER_SELECTED from "@/assets/images/map/ic_map_point_danger_selected.svg";
import ID_ICON_POINT_HOME_SELECTED from "@/assets/images/map/ic_map_point_home_selected.svg";
import ID_ICON_POINT_SEARCH_SELECTED from "@/assets/images/map/ic_map_point_search_selected.svg";
import ID_ICON_POINT_TARGET_SELECTED from "@/assets/images/map/ic_map_point_target_selected.svg";
import ID_ICON_POINT_STATION_SELECTED from "@/assets/images/map/ic_map_point_station_selected.svg";
import ID_ICON_WAYPOINT from "@/assets/images/map/ic_map_waypoint_circle.webp";
import ID_ICON_WAYPOINT_SELECTED from "@/assets/images/map/ic_map_waypoint_circle.webp";

export default {
  name: "GoogleMap",
  components: {},
  props: {
    mapHeight: {
      type: Number,
      default: 300,
    },
    zoom : {
      type: Number,
      default: 6.5,
    },
    lng: {
      type: Number,
      default: 127.97547912608519,
    },
    lat: {
      type: Number,
      default: 36.09510666745097,
    },
  },
  data() {
    return {
      map: null,
      polylineList: [],
      markerList: [],
      isLineShow: false,

      icons : null,

      //웨이포인트 사용 함수
      infoWindow: null,
      clickLatLng: null,
      waypointLineData: null,
      isPolylineClick: false,
      isMarkerMove: false,
      isPolylineOver: false,
      isMarkerClick: false,

      //마커 이동
      startX: null,
      startY: null,
      startOverlayPoint: null,
    };
  },

  updated() {
  },
  mounted() {
    if (window.google && window.google.maps) {
      this.initMap();
    }
  },
  created() {
    this.$emit("ready-map", false);
    this.setImageNameToIcons();
  },
  watch : {
    zoom(value){
      this.map.setZoom(value);
    },
  },
  methods: {
    temp (){
    },

    setLayout(){
      this.map.relayout();
    },
    setNotZoomable(){
      this.map.setZoomable(false);
    },
    initMap() {
      var container = document.getElementById("googleMap"); //지도를 담을 영역의 DOM 레퍼런스
      var options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: { lat : this.lat,
          lng : this.lng
        }, //지도의 중심좌표.
        zoom: this.zoom, //지도의 레벨(확대, 축소 정도)
        region: "KR",
        disableDefaultUI: true,
        mapTypeId: 'terrain'
      };

      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(container, options);
      this.$emit("ready-map", true);
      // 마커 클러스터러를 생성합니다
    },
    //카카오맵 초기화 함수 endregion

    //이미지 타입에 따른 클래스 이름 가져오기
    setImageNameToIcons(){
      this.icons = {
        "ID_ICON_DRONE" : {
          icon: ID_ICON_DRONE
        },
        "ID_ICON_USER": {
          icon: ID_ICON_USER
        },
        "ID_ICON_CONTROLLER": {
          icon: ID_ICON_CONTROLLER
        },
        "ID_ICON_OTHER_DRONE": {
          icon: ID_ICON_OTHER_DRONE
        },
        "ID_ICON_OTHER_USER": {
          icon: ID_ICON_OTHER_USER
        },
        "ID_ICON_OTHER_CONTROLLER": {
          icon: ID_ICON_OTHER_CONTROLLER
        },
        "ID_ICON_POINT_CAUTION": {
          icon: ID_ICON_POINT_CAUTION
        },
        "ID_ICON_POINT_DANGER": {
          icon: ID_ICON_POINT_DANGER
        },
        "ID_ICON_POINT_HOME": {
          icon: ID_ICON_POINT_HOME
        },
        "ID_ICON_POINT_SEARCH": {
          icon: ID_ICON_POINT_SEARCH
        },
        "ID_ICON_POINT_TARGET": {
          icon: ID_ICON_POINT_TARGET
        },
        "ID_ICON_POINT_STATION": {
          icon: ID_ICON_POINT_STATION
        },
        "ID_ICON_POINT_CAUTION_SELECTED": {
          icon: ID_ICON_POINT_CAUTION_SELECTED
        },
        "ID_ICON_POINT_DANGER_SELECTED": {
          icon: ID_ICON_POINT_DANGER_SELECTED
        },
        "ID_ICON_POINT_HOME_SELECTED": {
          icon: ID_ICON_POINT_HOME_SELECTED
        },
        "ID_ICON_POINT_SEARCH_SELECTED": {
          icon: ID_ICON_POINT_SEARCH_SELECTED
        },
        "ID_ICON_POINT_TARGET_SELECTED": {
          icon: ID_ICON_POINT_TARGET_SELECTED
        },
        "ID_ICON_POINT_STATION_SELECTED": {
          icon: ID_ICON_POINT_STATION_SELECTED
        },
        "ID_ICON_WAYPOINT": {
          icon: ID_ICON_WAYPOINT
        },
        "ID_ICON_WAYPOINT_SELECTED": {
          icon: ID_ICON_WAYPOINT_SELECTED
        },
      };
    },

    /**
     * 맵 타입 변경
     * @param type ROADMAP SKYVIEW HYBRID
     */
    onMapTypeChange(type) {
      switch (type) {
        case MapType.ID_NORMAL:
          // eslint-disable-next-line no-undef
          this.map.setMapTypeId("roadmap")
          break;
        case MapType.ID_TERRAIN:
          // eslint-disable-next-line no-undef
          this.map.setMapTypeId("terrain")
          break;
        case MapType.ID_SATELLITE:
          // eslint-disable-next-line no-undef
          this.map.setMapTypeId("satellite")
          break;
      }
    },

    onGetCenterPosition() {
      let position = this.map.getCenter();
      let result = {
        lat: position.lat(),
        lng: position.lng(),
      }
      return result;
    },

    onSetCenterPosition(position) {
      // eslint-disable-next-line no-undef
      this.map.setCenter({lat : position.lat, lng : position.lng});
    },
    // 마커 관리 함수 region
    onIsMarker(mapId) {
      if (this.markerList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.markerList.findIndex(isFindIndex);
        return index == -1 ? false : true;
      }
      return false;
    },

    /**
     * 마커 생성
     * @param markerData 마터 데이터
     */
    onCreateMarker(markerData) {
      // eslint-disable-next-line no-undef
      let position = {lat : markerData.lat, lng :  markerData.lng}
      var selectIcon = null;
      var iconName = null
      if(this.icons[markerData.imageName] != undefined) {
        iconName = this.icons[markerData.imageName].icon
      }
      if (markerData.color != undefined) {
        selectIcon = {
          // eslint-disable-next-line no-undef
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor : markerData.color,
          strokeColor : markerData.color,
          fillOpacity: 1,
        }
      } else {
        selectIcon = {
          url: iconName,
          scale : 0.5,
          // eslint-disable-next-line no-undef
          origin: new google.maps.Point(0, 0),
        };
      }

      // eslint-disable-next-line no-undef
      var marker = new google.maps.Marker({
        position: position,
        icon: selectIcon,
        title: markerData.mapId,
        map: this.map,
        draggable : this.isMarkerMove,
      });
      marker.id = markerData.mapId;

      if(markerData.mapDataType != undefined && markerData.mapDataType != null) {
        marker.mapDataType = markerData.mapDataType;
      }

      if(markerData.rotate == undefined) {
        markerData.rotate = 0;
      }

      var icon = marker.getIcon();

      icon.rotation = this.convert(Number(markerData.rotate));
      marker.setIcon(icon);

      if((markerData.name != null && markerData.name != "") || markerData.name === 0) {
        var text = "" + markerData.name
        let label = {
          color: '#fff',
          fontSize: '12px',
          fontWeight: '600',
          text: text
        }
        marker.setLabel(label);
      }
      this.markerDragEvent(marker)
      this.markerList.push(marker);
    },
    /**
     * 마커 생성
     * @param markerData 마터 데이터
     */
    onCreateMarkerIndex(markerData, index) {
      // eslint-disable-next-line no-undef
      let position = {lat : markerData.lat, lng :  markerData.lng}
      var selectIcon = null;
      var iconName = null
      if(this.icons[markerData.imageName] != undefined) {
        iconName = this.icons[markerData.imageName].icon
      }
      if (markerData.color != undefined) {
        selectIcon = {
          // eslint-disable-next-line no-undef
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor : markerData.color,
          strokeColor : markerData.color,
          fillOpacity: 1,
        }
      } else {
        selectIcon = {
          url: iconName,
          scale : 0.5,
          // eslint-disable-next-line no-undef
          origin: new google.maps.Point(0, 0),
        };
      }

      // eslint-disable-next-line no-undef
      var marker = new google.maps.Marker({
        position: position,
        icon: selectIcon,
        title: markerData.mapId,
        map: this.map,
        draggable : this.isMarkerMove,
      });

      marker.id = markerData.mapId;
      if(markerData.mapDataType != undefined && markerData.mapDataType != null) {
        marker.mapDataType = markerData.mapDataType;
      }

      if(markerData.rotate == undefined) {
        markerData.rotate = 0;
      }

      var icon = marker.getIcon();

      icon.rotation = this.convert(Number(markerData.rotate));
      marker.setIcon(icon);

      if((markerData.name != null && markerData.name != "") || markerData.name === 0) {
        var text = "" + markerData.name
        let label = {
          color: '#fff',
          fontSize: '12px',
          fontWeight: '600',
          text: text
        }
        marker.setLabel(label);
      }

      this.markerDragEvent(marker);

      this.markerList.splice(index, 0, marker);
    },

    markerDragEvent(marker){
      let obj = this;
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(marker, 'drag', function(e) {
        let lat = e.latLng.lat();
        let lng = e.latLng.lng();
        var index = marker.id.replace(marker.mapDataType + "_", "") - 1;
        let newPos = {lat: lat, lng: lng }
        obj.$emit("waypoint-change", index, newPos);
        if (obj.polylineList.length > 0) {
          var currentLineIndex = index - 1;
          var currentLine = null;
          var path = null;
          switch (index) {
            case 0 :
              currentLine = obj.polylineList[0];
              path = currentLine.getPath().getArray();
              path[0] = newPos;
              currentLine.setPath(path);
              break;
            case obj.markerList.length - 1:
              currentLine = obj.polylineList[obj.polylineList.length - 1];
              path = currentLine.getPath().getArray();
              path[1] = newPos;
              currentLine.setPath(path);
              break;
            default :
              currentLine = obj.polylineList[currentLineIndex];
              path = currentLine.getPath().getArray();
              path[1] = newPos;
              currentLine.setPath(path);

              var nextLineIndex = currentLineIndex + 1;
              var nextLine = obj.polylineList[nextLineIndex];
              var nextPath = nextLine.getPath().getArray();
              nextPath[0] = newPos;
              nextLine.setPath(nextPath);
          }
        }
      });

    },
    /**
     * 마커 수정
     * @param markerData 수정 마커 데이터
     */
    onModifyMarkerAt(markerData) {
      if (this.markerList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == markerData.mapId;
        };

        let index = this.markerList.findIndex(isFindIndex);
        if (index != -1) {
          let marker = this.markerList[index];
          if (markerData.lat != undefined && markerData.lat != null && markerData.lng != undefined && markerData.lng != null) {
            // eslint-disable-next-line no-undef
            marker.setPosition({lat : markerData.lat, lng : markerData.lng})
          }

          if(markerData.rotate == undefined) {
            markerData.rotate = 0;
          }

          var iconName = null
          if(this.icons[markerData.imageName] != undefined) {
            iconName = this.icons[markerData.imageName].icon
          }
          var scale = 0.5;
          if (markerData.isMain == true) {
            scale = 3;
          }
          var selectIcon = {
            url: iconName,
            scale : scale,
            // eslint-disable-next-line no-undef
            origin: new google.maps.Point(0, 0),
            rotation : this.convert(Number(markerData.rotate)),
          };
          marker.setIcon(selectIcon);
          var findDiv = this.$refs.map.querySelectorAll('div[title="'+markerData.mapId+'"]');
          if(findDiv.length > 0) {
            findDiv[0].firstChild.style.transform = 'rotate(' + selectIcon.rotation + 'deg)';
          }

          if (markerData.isShow != undefined) {
            marker.setVisible(markerData.isShow);
          }
        }
      }
    },

    /**
     * 마커 수정
     * @param markerData 수정 마커 데이터
     */
    onModifyMarkerList(markerData) {
      if (this.markerList.length > 0) {
        var filter = this.markerList.filter((item) => item.id.startsWith(markerData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {

            let marker = item;
            if (markerData.lat != undefined && markerData.lat != null && markerData.lng != undefined && markerData.lng != null) {
              // eslint-disable-next-line no-undef
              marker.setPosition({lat : markerData.lat, lng :  markerData.lng})
            }

            if(markerData.rotate == undefined) {
              markerData.rotate = 0;
            }
            var icon = marker.getIcon();
            var scale = 0.5;
            if (markerData.isMain == true) {
              scale = 3;
            }
            // eslint-disable-next-line no-undef
            icon.scale = scale // scaled size
            icon.rotation = this.convert(Number(markerData.rotate));
            marker.setIcon(icon);

            var findDiv = this.$refs.map.querySelectorAll('div[title="'+markerData.mapId+'"]');
            if(findDiv.length > 0) {
              findDiv[0].firstChild.style.transform = 'rotate(' + icon.rotation + 'deg)';
            }

            if (markerData.isShow != undefined) {
              marker.setVisible(markerData.isShow);
            }
          });
        }
      }
    },

    /**
     * 마커 제거
     * @param id 해당 마커 아이디
     */
    onDeleteMarkerAt(mapId) {
      if (this.markerList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.markerList.findIndex(isFindIndex);
        if (index != -1) {
          let marker = this.markerList[index];
          marker.setMap(null); // 지도에서 제거한다.
          this.markerList.splice(index, 1);
        }
      }
    },

    onDeleteMarkerIndex(markerDataType,index){
      var deleteLine = null;

      switch(index){
        case 0:
          if(this.polylineList.length > 0) {
            deleteLine = this.polylineList.splice(index,1);
            deleteLine[0].setMap(null);
          }
          break;
        case (this.markerList.length - 1) :
          deleteLine = this.polylineList.splice(this.polylineList.length - 1,1);
          deleteLine[0].setMap(null);
          break;
        default :
          deleteLine = this.polylineList.splice(index,1);
          if(deleteLine != null || deleteLine.length > 0) {
            var deletePath = deleteLine[0].getPath().getArray();
            var deletePosition = deletePath[deletePath.length - 1];

            var oldLine = this.polylineList[index - 1];
            var oldPath = oldLine.getPath().getArray();

            // eslint-disable-next-line no-undef
            oldPath[oldPath.length - 1] =  {lat :deletePosition.lat(), lng : deletePosition.lng() };
            oldLine.setPath(oldPath);
            deleteLine[0].setMap(null);
          }
      }
      let deleteMarker = this.markerList.splice(index,1);
      if(deleteMarker != null || deleteMarker.length > 0) {
        deleteMarker[0].setMap(null);
      }
    },
    /**
     * 타입 아이디로 리스트 제거
     * @param id 해당 마커 아이디
     */
    onDeleteMarkerList(markerData) {
      if (this.markerList.length > 0) {
        var filter = this.markerList.filter((item) => item.id.startsWith(markerData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.onDeleteMarkerAt(item.id);
          });
        }
      }
    },
    onSetIsMarkerMove() {
      this.isMarkerMove = true;
    },

    convert(deg) {
      return ((deg +360) % 360);
    },
    createCustomContent(markerData) {
      let className = this.getImageNameToClassName(markerData.imageName);
      let rootDiv = document.createElement("div");
      rootDiv.className = 'google-custom-div'
      if (className != null || markerData.mapDataType == MapDataType.ID_THUMBNAIL) {
        if (markerData.name != null && markerData.name != "") {
          let name = document.createElement("div");
          name.className = "showName"
          if (markerData.imageName == MapImageName.ID_ICON_WAYPOINT) {
            name.className += " waypoint"
          }

          name.innerText = markerData.name;
          rootDiv.appendChild(name);
        }

        let img = document.createElement("img");
        img.setAttribute("id", markerData.mapId + '-img');
        if(markerData.mapDataType != MapDataType.ID_THUMBNAIL ) {
          img.className = className;
        } else {
          if(markerData.thumbnail != null && markerData.thumbnail != undefined) {
            img.src = markerData.thumbnail
            img.className = "thumbnail_1";
          }
        }

        if(markerData.rotate == undefined) {
          markerData.rotate = 0;
        }
        var rotate =  this.convert(Number(markerData.rotate));
        //rotate = this.convert(rotate)
        //
        // if(rotate > 0) {
        //   rotate = rotate * 2;
        // }
        // if (rotate < 0) {
        //   rotate = this.convert(rotate);
        // }

        img.style.transform = 'rotate(' + rotate + 'deg)';
        rootDiv.appendChild(img);
      }

      if (markerData.color != undefined) {
        let circle = document.createElement("div");
        circle.className = "circle";
        circle.style.backgroundColor = markerData.color;
        circle.innerText = markerData.name;
        rootDiv.appendChild(circle);
      }

      return rootDiv;
    },

    distanceBetween(homeLatitude, homeLongitude, aircraftLatitude, aircraftLongitude) {
// 위도, 경도를 라디안 단위로 변환
      const lat1 = homeLatitude * Math.PI / 180;
      const lat2 = aircraftLatitude * Math.PI / 180;
      const lng1 = homeLongitude * Math.PI / 180;
      const lng2 = aircraftLongitude * Math.PI / 180;

      const Bx = Math.cos(lat2) * Math.cos(lng2 - lng1);
      const By = Math.cos(lat2) * Math.sin(lng2 - lng1);
      const lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2),
          Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By));
      const lng3 = lng1 + Math.atan2(By, Math.cos(lat1) + Bx);

      // 라디안을 디그리로 변환
      const valueLat = lat3 * 180 / Math.PI;
      let valueLng = lng3 * 180 / Math.PI;
      var result = {
        lng: valueLng,
        lat: valueLat
      }
      return result;
    },
    onCreateWindowInfoContext(mapDataType){
      var content = null;
      switch (mapDataType) {
        case MapDataType.ID_WAYPOINT :
          var rootDiv = document.createElement("div");
          rootDiv.className = "buttonWrap";
          rootDiv.style.display = "flex";
          rootDiv.style.marginTop = "20px";
          rootDiv.style.marginBottom = "20px";
          rootDiv.style.justifyContent = "center";

          var regBtn = document.createElement("button");
          regBtn.className = "point medium";
          regBtn.style.width = "80px";
          regBtn.style.marginLeft = "20px";

          if (this.isPolylineOver) {
            regBtn.innerText = this.$t("btn-add");
            regBtn.addEventListener("click", this.onWaypointAddBtnEvent, false)
          } else {
            regBtn.innerText = this.$t("btn-register");
            regBtn.addEventListener("click", this.onWaypointCreateBtnEvent, false)
          }

          rootDiv.appendChild(regBtn);

          var cancelBtn = document.createElement("button");
          cancelBtn.className = "medium margin6";
          cancelBtn.style.width = "80px";
          cancelBtn.style.marginRight = "20px";
          cancelBtn.innerText = this.$t("btn-cancel");
          cancelBtn.addEventListener("click", this.onWaypointCancelBtnEvent, false)
          rootDiv.appendChild(cancelBtn);
          content = rootDiv;
          break;
      }
      // eslint-disable-next-line no-undef
      this.infoWindow = new google.maps.InfoWindow({
        content: content,
        removable: true
      });
    },
    onSetIsWindowInfo(mapDataType) {
      var map = this.map;
      var obj = this;
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(map, 'click', function (mouseEvent) {
        if (obj.infoWindow != null) {
          obj.infoWindow.close();
          obj.infoWindow = null;
        }
        var latlng = mouseEvent.latLng;
        obj.clickLatLng = {
          lat: latlng.lat(),
          lng: latlng.lng()
        }
        obj.onCreateWindowInfoContext(mapDataType);
        // eslint-disable-next-line no-undef
        obj.infoWindow.setPosition({lat : latlng.lat(), lng : latlng.lng()});
        obj.infoWindow.open(map,);
      });
    },
    // 마커 관리 함수 endregion

    //웨이포인트 관련 함수 region
    onWaypointCreateBtnEvent() {
      if (this.clickLatLng != null) {
        this.$emit("waypoint-create", this.clickLatLng);
      }
      this.onWaypointCancelBtnEvent();
    },
    onWaypointAddBtnEvent() {
      if (this.waypointLineData != null) {
        var findIndex = this.waypointLineData.id.replace("ID_LIVE_", "");
        var position1 = this.markerList[findIndex - 1].getPosition();
        var position2 = this.markerList[findIndex].getPosition();
        var result = this.distanceBetween(position1.lat(), position1.lng(), position2.lat(), position2.lng());
        this.$emit("waypoint-add", findIndex, result);
      }
      this.onWaypointCancelBtnEvent();
    },
    onWaypointDeleteBtnEvent() {
      if (this.clickLatLng != null) {

        var findIndex = -1;
        if (this.polylineList.length > 0) {
          let path = this.polylineList[0].getPath();
          for (var i = 1; path.length; i++) {
            i;
          }
        }
        this.$emit("waypoint-delete", findIndex);
      }
      this.onWaypointCancelBtnEvent();
    },
    onWaypointCancelBtnEvent() {
      if (this.infoWindow != null) {
        this.infoWindow.close()
        this.infoWindow = null;
        this.clickLatLng = null;
        this.waypointLineData = null;
      }
    },

    onWaypointMarkerIdSort() {
      for (var i = 0; i < this.markerList.length; i++) {
        var index = i + 1;
        var marker = this.markerList[i];
        marker.id = "ID_WAYPOINT_" + index;
        let label = {
          color: '#fff',
          fontSize: '12px',
          fontWeight: '600',
          text: index.toString()
        }
        marker.setLabel(label);
      }
    },
    onWaypointLineIdSort(){
      for (var i = 0; i < this.polylineList.length; i++) {
        this.polylineList[i].id = "ID_LIVE_" + (i + 1);
      }
    },

    //웨이포인트 관련 함수 endregion
    // 라인 관리 함수 region
    onSetIsPolylineClick() {
      this.isPolylineClick = true;
    },
    /**
     * 라인 생성
     * @param lineData 라인 새성 데이터
     */
    onCreateLine(lineData) {
      var linePath = [];

      if (lineData.line.length > 0) {
        lineData.line.forEach(item => {
          // eslint-disable-next-line no-undef
          let latlng = new google.maps.LatLng(item.lat, item.lng);
          latlng.id = item.index;
          linePath.push(latlng);
        });

        var opacityValue = (lineData.isShow != undefined && lineData.isShow == true) ? 1 : 0;

        // eslint-disable-next-line no-undef
        var polyline = new google.maps.Polyline({
          map: this.map,
          path: linePath,
          strokeWeight: 3,
          strokeColor: lineData.color,
          strokeOpacity: opacityValue,
          click : false, //this.isPolylineClick,
        });

        polyline.id = lineData.mapId;
        polyline.sourceColor = lineData.color;
        polyline.linePathLength = linePath.length.toString();
        this.lineClickAddListener(polyline);

        this.polylineList.push(polyline);
      }
    },

    /**
     * 라인 생성
     * @param lineData 라인 새성 데이터
     */
    onCreateLineIndex(lineData, index) {
      var linePath = [];
      if (lineData.line.length > 0) {
        lineData.line.forEach(item => {
          // eslint-disable-next-line no-undef
          let latlng = new google.maps.LatLng(item.lat, item.lng);
          latlng.id = item.index;
          linePath.push(latlng);
        });

        var opacityValue = (lineData.isShow != undefined && lineData.isShow == true) ? 1 : 0;

        // eslint-disable-next-line no-undef
        var polyline = new google.maps.Polyline({
          map: this.map,
          path: linePath,
          strokeWeight: 3,
          strokeColor: lineData.color,
          strokeOpacity: opacityValue,
          click : false, //this.isPolylineClick,
        });
        polyline.id = lineData.mapId;
        polyline.sourceColor = lineData.color;
        polyline.linePathLength = linePath.length.toString();
        this.lineClickAddListener(polyline);

        let path = this.polylineList[index - 1].getPath().getArray();
        let newPath = [];
        for (var i = 0; i < path.length; i++) {
          if (i == 0) {
            var oldPosition = linePath[linePath.length - 1];

            newPath.push(oldPosition);
            continue;
          }
          // eslint-disable-next-line no-undef
          newPath.push(new google.maps.LatLng(path[i].lat(), path[i].lng()))
        }

        this.polylineList[index - 1].setPath(newPath);
        this.polylineList.splice(index - 1, 0, polyline);
      }
    },


    /**
     * 해당 아이디로 라인 지우기
     * @param id 지우는 라인 아이디
     */
    onDeleteLineAt(mapId) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          line.setMap(null); // 지도에서 제거한다.
          this.polylineList.splice(index, 1);
        }
      }
    },

    /**
     * 타입 아이디로 리스트 제거
     * @param id 해당 마커 아이디
     */
    onDeleteLineList(lineData) {
      if (this.polylineList.length > 0) {
        var filter = this.polylineList.filter((item) => item.id.startsWith(lineData.mapId));
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.onDeleteLineAt(item.id);
          });
        }
      }
    },

    /**
     * 라인 전체 사이즈 가져오기
     * @param id 해당 라인 아이디
     * @returns {length} 해당 라인의 전체 갯수
     */
    onGetLineSize(mapId) {
      var size = -1;
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          size = line.getPath().getArray().length;
        }
      }
      return size;
    },

    /**
     * 라인 기존에 추가
     * @param lineData 변경할 라인 데이터
     */
    onSetLineAdd(lineData) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == lineData.mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          let path = line.getPath();
          if (lineData.line.length > 0) {
            lineData.line.forEach(item => {
              // eslint-disable-next-line no-undef
              let latlng = new google.maps.LatLng(item.lat, item.lng);
              latlng.id = item.index;
              path.push(latlng);
            });
          }
          line.setPath(path);
          this.lineChangeOption(line, lineData);
        }
      }
    },

    /**
     * 라인 전체 변경
     * @param lineData 변경할 라인 데이터
     */
    onSetLineReplace(lineData) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == lineData.mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        if (index != -1) {
          let line = this.polylineList[index];
          let path = [];
          if (lineData.line.length > 0) {
            lineData.line.forEach(item => {
              // eslint-disable-next-line no-undef
              let latlng = new google.maps.LatLng(item.lat, item.lng);
              path.push(latlng);
            });
          }
          line.setPath(path);
          this.lineChangeOption(line, lineData)
        }
      }
    },

    onModifyLineList(lineData) {
      if (this.polylineList.length > 0) {
        var filter = this.polylineList.filter((item) => item.id.startsWith(lineData.mapId))
        if (filter.length > 0) {
          filter.forEach((item) => {
            this.lineChangeOption(item, lineData);
          });
        }
      }
    },

    onIsLine(mapId) {
      if (this.polylineList.length > 0) {
        var isFindIndex = (element) => {
          return element.id == mapId;
        };
        let index = this.polylineList.findIndex(isFindIndex);
        return index == -1 ? false : true;
      }
      return false;
    },
    /**
     * 현재 라인의 옵션 변경
     * @param mapLine 카카오라인
     * @param lineData 라인데이터
     */
    lineChangeOption(mapLine, lineData) {
      if (lineData.color != undefined) {
        mapLine.setOptions({strokeColor: lineData.color})
      }

      if (lineData.isShow != undefined) {
        var opacityValue = lineData.isShow == true ? 1 : 0;
        mapLine.setOptions({strokeOpacity: opacityValue});
      }

      if (this.isPolylineClick == true) {
        this.lineClickAddListener(mapLine);
      }
    },

    lineClickAddListener(mapLine) {
      var obj = this;
      var mpaLieData = mapLine;
      // 다각형에 mouseover 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 변경합니다
      // 지역명을 표시하는 커스텀오버레이를 지도위에 표시합니다
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(mapLine, 'mouseover', function () {
        obj.isPolylineOver = true;
        mapLine.setOptions({strokeColor: '#09f'});
      });
      // 다각형에 mouseout 이벤트를 등록하고 이벤트가 발생하면 폴리곤의 채움색을 원래색으로 변경합니다
      // 커스텀 오버레이를 지도에서 제거합니다
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(mapLine, 'mouseout', function () {
        obj.isPolylineOver = false;
        mapLine.setOptions({strokeColor: mapLine.sourceColor,});
      });

      // eslint-disable-next-line no-undef
      google.maps.event.addListener(mapLine, 'mousedown', function (e) {
        obj.waypointLineData = mpaLieData;
        // eslint-disable-next-line no-undef
        google.maps.event.trigger(obj.map, 'click', e);
      });
    },
    /**
     * 라인 전체 보이기, 가리기
     * @param isShow true - 보이기, false - 안보이기
     */
    onSetLineAllIsShow(isShow) {
      if (isShow == undefined) {
        return;
      }

      if (this.polylineList.length > 0) {
        var opacityValue = isShow == true ? 1 : 0;
        this.polylineList.forEach(item => {
          item.setOptions({
            strokeOpacity: opacityValue,
          });
        });
      }
    },
    // 라인 관리 함수 endregion

    // 공통 함수 region
    /**
     * 아이디 리스트 외 마커, 라인 제거
     * @param ids 제거하지 않는 아이디
     */
    onLeaveIds(mapDataType, ids) {
      var removeMarkerIds = [];
      var typeMarkerFilter = this.markerList.filter((item) => item.id.startsWith(mapDataType));
      typeMarkerFilter.forEach(item => {
        var filter = ids.filter(id => {
          return item.id == id
        });
        if (filter.length == 0) {
          removeMarkerIds.push(item.id)
        }
      });
      removeMarkerIds.forEach(id => {
        this.onDeleteMarkerAt(id);
      });

      var removeLineIds = [];
      var typeLikeFilter = this.polylineList.filter((item) => item.id.startsWith(mapDataType));
      typeLikeFilter.forEach(item => {
        var filter = ids.filter(id => {
          return item.id == id
        });
        if (filter.length == 0) {
          removeLineIds.push(item.id)
        }
      });
      removeLineIds.forEach(id => {
        this.onDeleteLineAt(id);
      });
    },

    onDeleteAllData() {
      if (this.markerList.length > 0) {
        this.markerList.forEach(item => {
          item.setMap(null); // 지도에서 제거한다.
        });
        this.markerList = [];
      }
      if (this.polylineList.length > 0) {
        this.polylineList.forEach(item => {
          item.setMap(null); // 지도에서 제거한다.
        });
        this.polylineList = [];
      }
    },
    // 공통 함수 endregion
  },
};
</script>

<style>

.google-custom-div > div.showName {
  width: 38px;
  height: 20px;
  margin: 10px 0 0;
  text-shadow: 0 0 2px #000;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.google-custom-div > div.waypoint {
  position: absolute;
  color: black;
  z-index: 1;
}

.google-custom-div > img {
  height: 40px;
  max-height: 40px;
}

.google-custom-div > img.active {
  height: 60px;
  max-height: 60px;
}

.google-custom-div > div.circle {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  color: white;
}

img.thumbnail_1 {
  width: 168px !important;
  max-width: 168px !important;
  height: 94.5px !important;
  max-height: 94.5px !important;
}

img.thumbnail_1 {
  width: 160px !important;
  max-width: 160px !important;
  height: 90px !important;
  max-height: 90px !important;
}

img.thumbnail_1 {
  width: 160px !important;
  max-width: 160px !important;
  height: 90px !important;
  max-height: 90px !important;
}

img.thumbnail_1 {
  width: 160px !important;
  max-width: 160px !important;
  height: 90px !important;
  max-height: 90px !important;
}

</style>
