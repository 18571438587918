import MapDataType from "@/components/map/enum/MapDataType";

class MapPolygonData {
    constructor () {
        //폴리곤 공용 데이터 
        this.id = null
        this.mapDataType = null 
        this.polygonType = null //몇각형인지에 대한 정보. 
        this.mapId = null
        this.lng = null
        this.lat = null
        this.line = null
        this.isShow = true
        this.color = null
        this.strokeWeight = null   //선 두께 
        this.strokeColor = null  //선 색상 
        this.strokeOpacity = null // 선 불투명도 
        this.strokeStyle = null //선 스타일
        this.fillColor = null  //채움 색 
        this.fillOpacity = null   //채움 불투명도
        this.zIndex = 0

        //n각형 
        this.posList = null

        //원 
        this.radius = 100

    }

    setStrokeData(data){
        this.strokeWeight = data.strokeWeight   //선 두께 
        this.strokeColor = data.strokeColor  //선 색상 
        this.strokeOpacity = data.strokeOpacity // 선 불투명도 
        this.strokeStyle = data.strokeStyle //선 스타일
    }

    setFillData(data){
        this.fillColor = data.fillColor  //채움 색 
        this.fillOpacity = data.fillOpacity 
    }
    setLatLng(data){
        this.lat = data.lat
        this.lng = data.lng
    }
    
    setNoFlyArea(type) {
        this.strokeWeight = 2   //선 두께 
        this.strokeOpacity = 0.7 // 선 불투명도 
        this.strokeStyle = "line" //선 스타일
        this.fillOpacity = 0.4 

        switch(type) {
            case MapDataType.ID_AREA_POLYGON_RESTRICTED: // 비행제한구역
                this.strokeColor = "#4de600"  //선 색상 
                this.fillColor = "#4de600"  //채움 색 
            break
            case MapDataType.ID_AREA_POLYGON_FORBIDDEN: // 비행금지구역
                this.strokeColor = "#e60000 "  //선 색상 
                this.fillColor = "#e60000 "  //채움 색 
            break
            case MapDataType.ID_AREA_POLYGON_CONTROL: // 관제권
                this.strokeColor = "#C3CEB9"  //선 색상 
                this.fillColor = "#C3CEB9"  //채움 색 
            break
            case MapDataType.ID_AREA_POLYGON_ALERT: // 경계구역
                this.strokeColor = "#855e1f"  //선 색상 
                this.fillColor = "#855e1f"  //채움 색 
            break
            case MapDataType.ID_AREA_POLYGON_DANGER:// 위험구역
                this.strokeColor = "#40dab0"  //선 색상 
                this.fillColor = "#40dab0"  //채움 색 
            break
        }
    }
    
    setPolygon(type){
        //공통 세팅
        this.mapDataType = type
        this.strokeStyle = 'dashed'
        this.strokewColor = '#FF00FF'
        this.color = `#31A2FF`
        this.isShow = true
        this.fillColor = `#31A2FF` //'#00EEEE' //채움 색


        //개인 세팅
        switch (type) {
            case MapDataType.ID_MAPPING_CIRCLE :
                this.strokeOpacity = 0.8
                this.strokeWeight = 5
                this.strokeColor = '#ffffff'
                this.fillColor = '#ffffff'
                this.fillOpacity = 0.3
                this.radius = 30 
                break;
            case MapDataType.ID_MAPPING_SQURE:
                this.strokeOpacity = 0.8
                this.strokeWeight = 0
                this.fillOpacity =  0.2
                break;
            // 추가적인 case들...
            case MapDataType.ID_MAPPING_POLYGON:
                break;
            case MapDataType.ID_MAPPING_HIDDEN_POLYGON :
                this.strokeOpacity = 0
                this.strokeWeight = 0
                this.fillOpacity =  0
                break;
            default:
        }
    }
}

export default MapPolygonData;