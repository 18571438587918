class MapLinePointData {
    constructor () {
        this.index = -1
        //좌표 경도 Longitude
        this.lng = -1
        //좌표 위도 Latitude
        this.lat =  -1
        //방향 값
        this.rotate =  0
        //시간
        this.time =  null

        this.gimbalDirection = null
    }
}

export default MapLinePointData;