<template>
  <div ondragstart="return false" class="kakao-map">
    <kakaoMap v-if="loadMapName == 'kakao'" :mapType="mapType" :lng="mapCenter.lng" :lat="mapCenter.lat"
      :zoom="zoomLevel" style="z-index:0; width: 100%; height: 100%; " ref="kakao" @ready-map="readyMap"
      @item-click="itemClick" @waypoint-create="createWaypointLatLng" @waypoint-change="changeWaypointLineLatLng"
      @waypoint-add="addWaypointLatlng" @mapping-create="createMappingLatLng" @mapping-change="changeMappingLatLng"
      @mapping-add="addMappingLatLng" />

    <googleMap v-if="loadMapName == 'google'" :mapType="mapType" :lng="mapCenter.lng" :lat="mapCenter.lat"
      :zoom="zoomLevel" style="z-index:0; width: 100%; height: 100%; position: absolute;" ref="google"
      @ready-map="readyMap" @waypoint-create="createWaypointLatLng" @waypoint-change="changeWaypointLineLatLng"
      @waypoint-add="addWaypointLatlng" />



    <!-- 위치 정보 표시 -->
    <div v-if="isLngLatShow" class="mapTop" style="z-index:2;"
      :style="[{ 'width': lnglatPositionWidth - 20 + 'px' }, { 'top': defaultShowHeight + getLngLatShowUnder + 'px' }, isRightLngLat == true ? { 'right': getWeatherUnder + (getPositionAndLienUnder) + 'px' } : { 'left': 'calc(50% - 183px)' }]">
      <div class="locationInfo">
        <div class="latLngName">위도</div>
        <div class="latValue">{{ lnglat.lat != null ? lnglat.lat.toFixed(9) : '-' }}</div>
        <div class="latLngName">경도</div>
        <div class="lngValue">{{ lnglat.lng != null ? lnglat.lng.toFixed(9) : '-' }}</div>
      </div>
    </div>

    <!-- 현장 누적 카운트 -->
    <!-- 20240117 update -->
    <!-- <div v-if="isLogCountShow" class="box flex infoCount" style="display: flex;flex-direction: column;background: rgba(16, 16, 16, 0.82);border: 0;">
      <h2>전체현장 운영 현황</h2>
      <div class="cntIn">
        <div class="cntIn-img">
          <img src="@/assets/images/icon_svg/drone_icon.png" alt="icon" />
        </div>
        <b v-if="accountId=='komipo'"> 로봇운행횟수</b>
        <b v-else > {{ $t("overall-data-cnt-log-drone") }}</b>
        <span>{{ deviceLogCount || 0 }}</span>
      </div>
      <div class="cntIn">
        <div class="cntIn-img"><img src="@/assets/images/icon_svg/vod_icon.png" alt="icon" /></div>
        <b> {{ $t("top-select-total-view-flight-vod") }}</b>
        <span>{{ vodCount || 0 }}</span>
      </div>
      <div class="cntIn">
        <div class="cntIn-img"><img src="@/assets/images/icon_svg/menber_icon.png" alt="icon" /></div>
        <b> {{ $t("overall-data-cnt-log-member") }}</b>
        <span>{{ memberCount || 0 }}</span>
      </div>
    </div> -->

    <!--  전체 누적 카운트(종합상황판)  -->
    <div v-if="isAllLogCountShow" class="box flex allinfoCount">
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/ic_all_site.png" alt="icon" />
        <b> {{ $t("overall-data-event") }}</b>
        <span>{{ eventOpenCount || 0 }}</span>
      </div>
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/drone_icon.png" alt="icon" />
        <b> {{ $t("overall-data-now-device") }}</b>
        <span>{{ deviceLiveCount || 0 }}</span>
      </div>
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/menber_icon.png" alt="icon" />
        <b> {{ $t("overall-data-now-member") }}</b>
        <span>{{ workerLiveCount || 0 }}</span>
      </div>
      <div class="flexB cntIn" style="height: auto; justify-items: center;">
        <img src="@/assets/images/icon_svg/vod_icon.png" alt="icon" />
        <b> {{ $t("top-select-total-view-flight-vod") }}</b>
        <span>{{ vodLiveCount || 0 }}</span>
      </div>
    </div>

    <!-- 라이브 진행 정보 -->
    <div v-if="isAllLiveCountShow" @click="openLiveModal" class="map-live-count">
      <h2><img src="@/assets/images/icon_svg/live-icon.png">실시간 라이브</h2>
      <h4>0</h4>
    </div>
    <div v-if="isLiveModalOpen" class="live-modal">
      <div class="vod-modal-content">
        <div class="modal-content-header">
          <h2>라이브영상 검색</h2>
          <button @click="closeLiveModal"><img src="@/assets/images/icon_svg/close-icon.png" alt="icon" /></button>
        </div>
        <div class="modal-content-body">
          <div class="modal-table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>순번</th>
                  <th>디바이스 타입</th>
                  <th>디바이스 이름</th>
                  <th>소속</th>
                  <th>조종사</th>
                  <th>촬영시작일시</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="checkbox" name="liveCheckBox"></td>
                  <td></td>
                  <td> </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-content-footer">
          <button>확인</button>
          <button @click="closeLiveModal">취소</button>
        </div>
      </div>
    </div>



    <div ref="map-img-name-list" id="map-img-name-list" style="display: none;"></div>
  </div>
</template>

<script>
import { fetchPointCreate } from "@/api/point";
import moment from "moment";
import axios from "axios";
import kakaoMap from "@/components/map/kakao/KaKaoMap";
import googleMap from "@/components/map/google/GoogleMap";

import MapDataType from "@/components/map/enum/MapDataType";
import MapType from "@/components/map/enum/MapType";
import MapImageName from "@/components/map/enum/MapImageName";
import MapCityType from "@/components/map/enum/MapCityType";
import MapUtils from "@/components/common/MapUtils";

import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapLinePointData from "@/components/map/data/MapLinePointData";
import MapKoreaCityPosition from "@/components/map/city/MapKoreaCityPosition";

// import icDeleteGr from "@/assets/images/icon_svg/ic_delete_gr.svg"
// import icDrone from "@/assets/images/icon_svg/ic_ds_all_drone.svg"
// import icMapDroneCameraAngle from "@/assets/images/map/ic_map_drone_camera_angle2.png";
import MapIcon from "@/components/map/data/MapIcon";
// import icLNBRegisterBk from "@/assets/images/icon_svg/LNB_register_bk.svg";
import { mapState } from "vuex";

import * as MapCuatoemHtml from "@/components/map/customhtml/custom-marker.js";

// import {ButtonIsShow} from "./mixins/ButtonIsShow";
// import {ShowUnder} from "./mixins/ShowUnder";
// import {MapMixins} from "./mixins/MapMixins";

import mapButtonEventBus from "@/components/map/mapButtonEventBus";
import MapPolygonData from "./data/MapPolygonData";

export default {
  name: "MapView",
  mixins: [],
  components: {
    kakaoMap,
    googleMap,
  },
  props: {
    mapType: {
      type: Object,
      default() {
        return MapType.ID_SATELLITE
      },
    },
    defaultZoomLevel: {
      type: Number,
      default: 6,
    },
    loadMapName: {
      type: String,
    },

    isLeftButtonPosition: {
      type: Boolean,
      default: false,
    },
    isRightAddVideoPosition: {
      type: Boolean,
      default: false,
    },
    isRightLngLat: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      MapUtils: new MapUtils(),
      interval: null,
      moment: moment,
      isLngLatShow: false,
      mapDomWidth: null,
      //버튼 전체 값 region
      zoomLevel: this.defaultZoomLevel,
      isFollow: true,
      //버튼 전체 값 endregion

      //날씨 정보 region
      isShowWeather: false,
      weatherInfo: null,
      weatherPositionWidth: 130,
      lnglatPositionWidth: 280,
      //날씨 정보 endregion

      //현위치, 라인 관련 변수 region
      isShowLine: false,
      isShowNoFly: true,
      //현위치, 라인 관련 변수 endregion

      //맵 타입 변경 관련 변수 region
      vueMapType: MapType,


      //맵 타입 변경 관련 변수 endregion

      //주요지점 관련 변수 region
      //isPointShowButton:false,
      pointButtonShowTop: 0,
      pointSelectType: 'Caution',

      isPointModify: false,
      pointMemo: null,
      pointData: null,

      //드론 비행 알림 공지 팝업
      isFlightAlertModal: false,

      eventLogList: [],

      //현장 누적 카운트
      isLogCountShow: false,
      deviceLogCount: 0,
      deviceLiveCount: 0,
      workerLiveCount: 0,
      vodCount: 0,
      vodLiveCount: 0,
      memberCount: 0,
      eventOpenCount: 0,

      //종합현황판 누적 카운트
      isAllLogCountShow: false,

      //라이브 카운트
      isAllLiveCountShow: false,
      //맵 관련 region
      mapCenter: {
        //lat: 36.09510666745097,
        //lng: 127.97547912608519
        lat: this.latDefault,
        lng: this.lngDefault
      },
      latDefault: 36.09510666745097, //기본 좌표
      lngDefault: 127.97547912608519,//기본 좌표


      mapReady: false,
      //각각의 데이터 항목의 보이는 데이터 여부 값들
      isDroneShow: true,
      isControllerShow: true,
      isUserShow: true,
      isPointShow: true,

      //맵 전체 데이터 관리
      redColor: "#ff4600",
      grayColor: "#24FF7C",
      missionLineMainColor: "#3692ff",
      missionLineSubColor: "#76b1ff",
      vodLiveColor: ["#ff4600", "#2B79EE", "#FF7F33", "#9E30FF"],  //{0 : "#ff4600", 1 : "#2B79EE", 2 : "#FF7F33" , 3 :"#9E30FF"},

      lnglat: {
        lng: null,
        lat: null,
      },
      //영상 관련 데이터
      playData: {
        markerData: null,
        LineData: null,
        LineFullData: null,
      },
      dataUrl: null,

      //모달
      isLiveModalOpen: false,
      eventId: null,

      prevTime: 0
    };
  },
  mounted() {
    this.initMapButtonEvnet()
    this.mapInstance.onMapTypeChange(this.mapType);
    let keys = Object.keys(MapIcon);
    let imgNameList = this.$refs["map-img-name-list"];

    keys.forEach((key) => {
      let icon = MapIcon[key].icon;
      if (icon != undefined && icon != null) {
        let findIndex = icon.lastIndexOf(".svg");
        // if(findIndex === -1) {
        //   findIndex = icon.lastIndexOf( ".png");
        // }
        if (findIndex != -1) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          var base_image = new Image();
          base_image.src = icon;
          base_image.onload = function () {
            canvas.width = base_image.width;
            canvas.height = base_image.height;
            ctx.drawImage(base_image, 0, 0);
            canvas.setAttribute("data-map-img-id", key);
            imgNameList.append(canvas);

          }
        }
      }
    });
  },
  created() {
    // this.$IsShowEventBus.$emit("isLeftButtonPosition", this.isLeftButtonPosition);
    this.$store.dispatch("map/IS_LEFT_BUTTON_POSITION", this.isLeftButtonPosition);
    this.$store.dispatch("map/IS_RIGHT_ADD_VIDEO_POSTION", this.isRightAddVideoPosition);
    this.zoomLevel = this.defaultZoomLevel;
    this.eventId = this.$route.params.eventId
  },
  computed: {

    ...mapState({
      defaultShowHeight: state => state.map.defaultShowHeight,
      topDefault: state => state.map.topDefault,
      defaultButtonShowHeight: state => state.map.defaultButtonShowHeight,


      isLngLatShowUnder: state => state.map.isLngLatShowUnder,
      isPositionAndLienUnder: state => state.map.isPositionAndLienUnder,

      positionAndLienUnder: state => state.map.positionAndLienUnder,
      defaultLineValue: state => state.map.defaultLineValue,
      lngLatShowUnder: state => state.map.lngLatShowUnder,

      accountId: state => state.store.accountId,

    }),
    getIsLngLatShowUnder() {
      return this.isLngLatShowUnder;
    },
    getLngLatShowUnder() {
      return this.isLngLatShowUnder ? 53 : 0;
    },
    getPositionAndLienUnder() {
      return this.isPositionAndLienUnder ? 0 : 180;
    },
    getWeatherUnder() {
      var defaultLineValue = this.defaultLineValue;
      if (this.isShowWeather && this.isLeftButtonPosition) {
        defaultLineValue = defaultLineValue + this.defaultLineValue + this.weatherPositionWidth;
      }
      return defaultLineValue;
    },
    mapInstance: {
      get: function () {
        var instance = null;
        console.log("loadMapName", this.loadMapName)
        switch (this.loadMapName) {
          case "mapbox":
            instance = this.$refs.mapBox;
            break;
          case "kakao":
            instance = this.$refs.kakao;
            break;
          case "google":
            instance = this.$refs.google;
            break;
        }
        return instance;
      },
    },
  },
  watch: {
    pointData: function () {
      if (this.pointData != null) {
        this.pointSelectType == this.pointData.type;
      }
    },
    mapDomWidth(change) {
      if (change > (540 + 30 + 30 + 134 + 30)) {
        this.$store.dispatch("map/IS_POSITION_AND_LIEN_UNDER", false);
        this.$store.dispatch("map/IS_LNG_LAT_SHOW_UNDER", false);
      } else if (change <= (540 + 30 + 30 + 134 + 30) && change > (540 + 30 + 30)) {
        this.$store.dispatch("map/IS_POSITION_AND_LIEN_UNDER", true);
        this.$store.dispatch("map/IS_LNG_LAT_SHOW_UNDER", false);
      } else {
        this.$store.dispatch("map/IS_LNG_LAT_SHOW_UNDER", true);
      }

    },
    lnglat: {
      deep: true,
      handler() {
      },
    },
  },
  methods: {

    /**
     * 240904 - 신현호
     * 맵버튼들 이벤트
     */
    initMapButtonEvnet() {
      // 맵타입 선택 이벤트
      mapButtonEventBus.$on('selector/mapTypeChange', (type) => {
        this.typeSelectItemName(type)
      })

      // 맵센터 이벤트
      mapButtonEventBus.$on('button/moveToCenter', (eventInfo) => {
        if (eventInfo.lng != null && eventInfo.lng != "" && eventInfo.lat != null && eventInfo.lat != "") {
          this.onLngLatToMoveCenter(eventInfo.lng, eventInfo.lat)
        } else {
          this.onAddressToMoveCenter(eventInfo.address);
        }
      })

      // 맵포인트 등록
      mapButtonEventBus.$on('modal/createNewPoint', (data) => {
        this.onPointCreate(data)
      })


      mapButtonEventBus.$on("button/moveMapUserCenter", (ip) => {
        this.onLngLatToMoveCenter(ip.ipToLng, ip.ipToLat);
      })

      mapButtonEventBus.$on('positionAndline', (isShowLine) => {
        this.onClickShowLine(isShowLine)
      })

      mapButtonEventBus.$on("isMapFollow", (isFollow) => {
        this.isFollow = isFollow

      })

      mapButtonEventBus.$on("toggleAirspace", (isShowNoFly) => {
        this.onClickShowPolygon(isShowNoFly, "ID_AREA_POLYGON_")
      })

      mapButtonEventBus.$on("drawAirspace", (polygon, mapDataType) => {
        this.onCreateMappingPolygon(polygon, mapDataType)
        // this.onCreateNoFlyPolygon(polygon, mapDataType)
      })


    },

    /**
     * 맵 타입 변경 이미지 선택
     */
    typeSelectItemName(selectedMapType) {
      if (this.typeSelectName == selectedMapType.name) {
        return;
      }
      this.typeSelectName = selectedMapType.name;
      this.mapInstance.onMapTypeChange(selectedMapType);
    },


    onGetMapInfoHtmlContent(dataType, markerData, data, event) {
      var rootDiv = null;
      switch (markerData.mapDataType) {
        case MapDataType.ID_SITE_CIRCLE:
          rootDiv = MapCuatoemHtml.MapInfoHtmlEvent(data, event.bind(null, data.eventId, data), this);
          break;
        case MapDataType.ID_POINT:
          rootDiv = MapCuatoemHtml.MapInfoHtmlToPointDetail(data, event, this);
          break;
        case MapDataType.ID_MAP_INFO_USER:
          MapCuatoemHtml.MapInfoHtmlToUser(data, event.bind(null, data));
          break;
        case MapDataType.ID_MAP_INFO_DRONE:
          rootDiv = MapCuatoemHtml.MapInfoHtmlToDroneDetail(data, event.bind(null, data));
          break;
        case MapDataType.ID_MAP_INFO_POINT:
          rootDiv = MapCuatoemHtml.MapInfoHtmlToPointModify(data, event, this);
          break;
      }
      return rootDiv;
    },
    onGetHtmlContent(markerData) {

      let rootDiv = null;
      let icon = MapIcon[markerData.imageName];
      if (icon != null && icon != undefined) {
        markerData.icon = icon.icon;
      }

      switch (markerData.mapDataType) {
        case MapDataType.ID_SITE:
          rootDiv = MapCuatoemHtml.MapCustomMarkerEventCenter(markerData, null);
          break;
        case MapDataType.ID_STATION:
          rootDiv = MapCuatoemHtml.MapCustomMarkerEventCenter(markerData, null);
          break;
        case MapDataType.ID_CITY:
          rootDiv = MapCuatoemHtml.MapCustomMarkerCircle(markerData);
          break;
        case MapDataType.ID_PLAYER_MARKER:
          rootDiv = MapCuatoemHtml.MapCustomMarkerPlayerDrone(markerData);
          break;
        case MapDataType.ID_USER:
        case MapDataType.ID_CONTROLLER:
          rootDiv = MapCuatoemHtml.MapCustomMarkerUser(markerData);
          break;
        case MapDataType.ID_STREAMER:
          rootDiv = MapCuatoemHtml.MapCustomMarkerStreamer(markerData);

          break
        case MapDataType.ID_DRONE:
          if (markerData.isMain) {
            rootDiv = MapCuatoemHtml.MapCustomMarkerDeviceDroneDetail(markerData);
          } else {
            rootDiv = MapCuatoemHtml.MapCustomMarkerDeviceDrone(markerData);
          }

          break;
        case MapDataType.ID_POINT:

          if (markerData.isMain) {
            rootDiv = MapCuatoemHtml.MapCustomMarkerPointDetail(markerData);
          } else {
            rootDiv = MapCuatoemHtml.MapCustomMarkerPoint(markerData, null);
          }
          break;
        case MapDataType.ID_WAYPOINT:
          rootDiv = MapCuatoemHtml.MapCustomMarkerWaypoint(markerData, null);
          break;
        case MapDataType.ID_SITE_CIRCLE:
          rootDiv = MapCuatoemHtml.MapCustomMarkerEventCircle(markerData, null);
          break;
        case MapDataType.ID_MAPPING_MARKER: //mapping 전용 마커
          rootDiv = MapCuatoemHtml.MapCustomMarkerMapping(markerData, null);
          break;
        // 240814 - 신현호
        case MapDataType.ID_SCHEDULE_STATION:
          rootDiv = MapCuatoemHtml.MapCustomMarkerStation(markerData);
          break
        case MapDataType.ID_SCHEDULE_STATION_NUMBER:
          rootDiv = MapCuatoemHtml.MapCustomMarkerStationNumber(markerData);
          break
        case MapDataType.ID_MAP_AI_MARKER:
          rootDiv = MapCuatoemHtml.MapCustomMarkerAI(markerData);
          break
        default: {
          rootDiv = null;
        }
      }
      return rootDiv;
    },

    onCreateMapMarker(id, lng, lat, mapDataType) {
      let mapMarkerData = new MapMarkerData();
      mapMarkerData.id = id;
      mapMarkerData.lng = lng;
      mapMarkerData.lat = lat;
      mapMarkerData.mapDataType = mapDataType;

      // 기존에 마커가 있다면 수정을함.
      if (this.onIsMarker(mapMarkerData.mapDataType, id)) {
        this.onModifyMarkerAt(mapMarkerData.mapDataType, mapMarkerData);
      } else {
        this.onCreateMarker(mapMarkerData.mapDataType, mapMarkerData);
      }
    },
    onSetDraggable(draggable) {
      // 마우스 드래그로 지도 이동 가능여부를 설정합니다
      this.mapInstance.onSetDraggable(draggable);
    },
    onSetLngLat(lng, lat) {
      this.lnglat.lat = lat;
      this.lnglat.lng = lng;
    },

    moveSmooth(lng, lat, index, location) {
      lng, lat, index, location

      const projection = this.mapInstance.getProjection()
      console.log(projection.pointFromCoords())

      const prevPosition = this.mapInstance.getNewLatLngMap(lat, lng)
      const nextPosition = this.mapInstance.getNewLatLngMap(location[index + 1].lat, location[index + 1].lng)
      const prevScreen = projection.pointFromCoords(prevPosition)
      const nextScreen = projection.pointFromCoords(nextPosition)
      prevScreen, nextScreen

      const markerMove = () => {
        if (index > 1) {
          this.onSetLngLat(location[index + 1].lng, location[index + 1].lat);
        }
      }
      window.requestAnimationFrame(markerMove);
    },

    MapInterpolation(prev, next) {
      if (prev.y === next.y && prev.x === next.x) {
        return { x: prev.x, y: prev.y }
      }

      const inclination = (prev.y - next.y) / (prev.x - next.x);//기울기
      const re_x = prev.x > next.x ? prev.x - 0.5 : prev.x + 0.5;//다음 마커의 x좌표
      const re_y = inclination * (re_x - prev.x) + prev.y;//다음 마커의 y좌표

      // console.log(re_x, re_y)
      return { x: re_x, y: re_y }
    },


    setLayout() {
      if (this.mapInstance != null) {
        this.mapInstance.setLayout();
      }
    },
    setWeatherInfoEventId(eventId) {
      if (this.$refs.weatherInfo != undefined) {
        this.$refs.weatherInfo.setEventId(eventId);
      }
    },
    getWeatherInfo() {
      if (this.$refs.weatherInfo != undefined) {
        return this.$refs.weatherInfo.getWeatherInfo();
      }
      return null;
    },
    onShowWeatherInfo() {
      this.isShowWeather = true;
    },
    onIsWeatherClick() {
      this.$emit("weather-click");
    },
    setNotZoomable() {
      this.mapInstance.setNotZoomable()
    },
    addWaypointLatlng(index, latlng) {
      this.$emit("waypoint-add", index, latlng);
    },
    changeWaypointLineLatLng(index, latlng) {
      this.$emit("waypoint-change", index, latlng);
    },
    createWaypointLatLng(latlng) {
      this.$emit("waypoint-create", latlng)
    },

    onWaypointMarkerIdSort() {
      this.mapInstance.onWaypointMarkerIdSort();
    },
    onWaypointLineIdSort() {
      this.mapInstance.onWaypointLineIdSort();
    },
    createMappingLatLng(latlng) {
      this.$emit("mapping-create", latlng)
    },
    changeMappingLatLng(index, latlng) {
      this.$emit("mapping-change", index, latlng)
    },
    addMappingLatLng(index, latlng) {
      this.$emit("mapping-add", index, latlng)
    },
    updateMapping(latlng, polygonPath) {
      this.$emit("mapping-update", latlng, polygonPath)
    },
    onMappingMarkerIdSort() {
      this.mapInstance.onMappingMarkerIdSort();
    },
    onMappingLineIdSort() {
      this.mapInstance.onMappingLineIdSort();
    },
    getReadyMap() {
      return this.mapReady;
    },
    readyMap(isReady) {
      this.mapReady = isReady;
      this.$emit("ready-map", this.mapReady)
    },
    getMapCenter() {
      return this.mapInstance.onGetCenterPosition();
    },


    onSetIsWindowInfo(mapDataType) {
      this.mapInstance.onSetIsWindowInfo(mapDataType);
    },

    itemClick(id, type, isMain) {
      this.$emit('item-click', id, type, isMain);
    },
    //textarea 글자 수 제한
    lengthLimit() {
      if (this.pointMemo.trim().length >= 201) {
        this.pointMemo = this.pointMemo.slice(0, -1);
        alert(this.$t("alert-message-characters-over"));
      }
    },



    /**
     * 맵 포지션, 라인 버튼 클릭
     * 화면에 따라 기본 라인 보여줄수 있음
     * 이동경로 선택 시 라인 보이게 만듬
     */
    onClickShowLine(isShowLine) {
      if (this.isShowLine != isShowLine) {
        this.isShowLine = isShowLine;
        if (this.isShowLine == true) {
          const lineData = new MapLineData();
          lineData.mapId = MapDataType.ID_DRONE;
          lineData.isShow = this.isDroneShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);

          lineData.mapId = MapDataType.ID_CONTROLLER;
          lineData.isShow = this.isControllerShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);

          lineData.mapId = MapDataType.ID_USER;
          lineData.isShow = this.isUserShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);

          lineData.mapId = MapDataType.ID_STREAMER;
          lineData.isShow = this.isUserShow && this.isShowLine;
          this.mapInstance.onModifyLineList(lineData);
        } else {
          this.mapInstance.onSetLineAllIsShow(this.isShowLine);
        }
      }
    },

    onClickShowPolygon(isShowNoFly, mapDataType) {

      if (this.isShowNoFly != isShowNoFly) {
        this.isShowNoFly = isShowNoFly;

        const polygon = new MapPolygonData()


        polygon.isShow = this.isShowNoFly;
        polygon.mapDataType = mapDataType
        this.mapInstance.onModifyPolygonList(polygon);
      }
    },



    onOverlayColorChange(MapDataType, colors) {
      this.mapInstance.onOverlayColorChange(MapDataType, colors);
    },




    /**
     * 현장 중심 버튼(아이콘 모양) 보이게 하기
     * */
    onShowLngLat() {
      this.isLngLatShow = true;
    },



    //도시별 마커 표시 및 데이터 region
    onCityMarkerCreate(mapCityType, markerDataList) {
      switch (mapCityType) {
        case MapCityType.ID_KOREA:
          var data = Object.assign({}, MapKoreaCityPosition);
          var positionKey = Object.keys(data);
          markerDataList.forEach(item => {
            var filter = positionKey.filter(find => {
              return "ID_" + item.id.toUpperCase() == find
            });
            if (filter.length > 0) {
              let key = filter[0];
              item.lng = data[key].point.lng;
              item.lat = data[key].point.lat;
              item.color = data[key].color;
              item.mapDataType = MapDataType.ID_CITY;
              item.html = this.onGetHtmlContent(item);
              this.mapInstance.onCreateMarker(item);
            }
          });

          break;
      }
    },
    //도시별 마커 표시 및 데이터 endregion



    /**
     * 포인트 타엽 변경
     * @param select 생성 할 포인트 타입 변경
     */
    pointTypeChange(select) {
      this.pointSelectType = select;
    },


    /**
     * 신현호
     * 포인트 생성함수, 이벤트 연결
     * @param data
     */
    onPointCreate(data) {

      const position = this.mapInstance.onGetCenterPosition();
      let sendData = {
        ...data,
        lng: position.lng,
        lat: position.lat
      };
      fetchPointCreate(this.eventId, sendData).then((res) => {
        if (res.data.result == 0) {

          // this.$refs.map.onPointCreateModalClose();
        }
      });

    },




    /**
     * 맵 외부 연결
     * @param isShow null, true, false (null: 현재 값에 반대 값 넣기 )
     */
    onGetIsPointCreate() {
      return this.isPointCreate;
    },
    //포인트 관련 함수 endregion

    // 맵 관련 함수 region
    /**
     * 주소를 이용한 좌표 가져온 후 센터 이동
     * 카카오 api 이용
     * @param addr
     */
    onAddressToMoveCenter: function (addr) {
      const url = "https://dapi.kakao.com/v2/local/search/address.json?query=" + addr;
      axios.get(url, {
        headers: {
          "Authorization": "KakaoAK 469a7fd4cb1f60077561c136389b0b8c", //8b2ba5acde398e492c2a0e5f05d8b2a8
          "content-type": "application/json"
        }
      })
        .then((resp) => {
          var results = resp.data.documents;
          if (results.length > 0) {
            var longitude = results[0].x;
            var latitude = results[0].y;
            let point = {
              lng: longitude,
              lat: latitude,
            }
            this.mapCenter = point;
            //TODO 맵 위치 이동
            this.mapInstance.onSetCenterPosition(this.mapCenter);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    onUserCenterPosition: function (lng, lat) {
      this.onLngLatToMoveCenter(lng, lat);
    },
    /**
     * 좌표 값으로 위치 맵 센터 이동
     * @param lng
     * @param lat
     */
    onLngLatToMoveCenter: function (lng, lat) {
      let point = {
        lng: lng,
        lat: lat,
      }
      this.mapCenter = point;
      if (this.mapInstance != undefined) {
        this.mapInstance.onSetCenterPosition(this.mapCenter);
      }
    },

    /**
     * default 좌표로 이동
     * */
    moveToDefaultCoordinates: function () {
      let point = {
        lng: this.lngDefault,
        lat: this.latDefault,
      }
      this.mapCenter = point;
      if (this.mapInstance != undefined) {
        this.mapInstance.onSetCenterPosition(this.mapCenter);
      }
    },

    /**
     * 맵 드론 비행 알람 팝업 보여주기
     * @param isShow null, true, false (null: 현재 값에 반대 값 넣기 )
     */
    FlightAlertModal(isInfoShow) {
      this.isFlightAlertModal = isInfoShow;
    },


    /**
     * 맵 전체 데이터 제거
     */
    onClearMapData() {
      this.mapInstance.onDeleteAllData();
    },

    /**
     * 타입별 데이터 보여짐 여부 설정
     * @param mapDataType
     * @param isShow
     */
    onSetIsShowDataType(mapDataType, isShow) {
      switch (mapDataType) {
        case MapDataType.ID_DRONE:
          this.isDroneShow = isShow;
          break;
        case MapDataType.ID_CONTROLLER:
          this.isControllerShow = isShow;
          break;
        case MapDataType.ID_USER:
          this.isUserShow = isShow;
          break;
        case MapDataType.ID_POINT:
          this.isPointShow = isShow;
          break;
      }

      const markerData = new MapMarkerData();
      markerData.mapId = mapDataType;
      markerData.isShow = isShow;

      const lineData = new MapLineData();
      lineData.mapId = mapDataType;
      lineData.isShow = isShow;

      this.mapInstance.onModifyMarkerList(markerData);
      this.mapInstance.onModifyLineList(lineData);
    },

    getIsShowDataType(mapDataType) {
      var result = true;
      switch (mapDataType) {
        case MapDataType.ID_DRONE:
          result = this.isDroneShow;
          break;
        case MapDataType.ID_CONTROLLER:
          result = this.isControllerShow;
          break;
        case MapDataType.ID_USER:
          result = this.isUserShow;
          break;
        case MapDataType.ID_POINT:
          result = this.isPointShow;
          break;
      }
      return result;
    },

    /**
     * 지도 zoom 레벨 설정을 한다.
     * @param zoomLevel
     */
    onSetZoomLevel(zoomLevel) {
      this.zoomLevel = zoomLevel;
    },
    // 맵 관련 함수 endregion



    // 영상 관련 함수 region
    /**
     * 영상 재생을 위한 함수
     * @param mapDataType 데이터 타입
     * @param mapLineData 라인 데이터
     */
    onPlayRemoveData() {
      if (this.playData.LineFullData != null) {
        this.mapInstance.onDeleteMarkerAt(this.playData.markerData.mapId);
        this.mapInstance.onDeleteLineAt(this.playData.LineData.mapId);
        this.mapInstance.onDeleteLineAt(this.playData.LineFullData.mapId);
        this.playData.markerData = null;
        this.playData.LineData = null;
        this.playData.LineFullData = null;
      }
    },
    onPlayCreateData(mapLineData) {
      if (this.playData.LineFullData != null) {
        this.onPlayRemoveData();
      }

      //전체 라인 데이터 설정
      this.playData.LineFullData = mapLineData;
      this.playData.LineFullData.isShow = true;
      this.playData.LineFullData.locationGroup.sort(function (a, b) {
        return a.index - b.index;
      });

      //현재 라인 데이터 설정
      this.playData.LineData = JSON.parse(JSON.stringify(this.playData.LineFullData));

      //전체 라인 데이터 값 넣기
      this.playData.LineFullData.mapId = MapDataType.ID_PLAYER_LINE_FULL + "_" + mapLineData.id;
      this.playData.LineFullData.line = this.createLinePointDataList(this.playData.LineFullData.locationGroup);
      this.playData.LineFullData.color = this.grayColor;

      //현재 라인 데이터 값 넣기
      this.playData.LineData.mapId = MapDataType.ID_PLAYER_LINE + "_" + mapLineData.id;
      this.playData.LineData.line = this.playData.LineFullData.line.slice(0, 1);
      this.playData.LineData.color = this.redColor;

      //맵 전체 라인 생성
      this.mapInstance.onCreateLine(this.playData.LineFullData);
      //맵 첫번째 라인 생성
      this.mapInstance.onCreateLine(this.playData.LineData);

      //맵 마커 생성
      if (this.playData.LineFullData.line.length > 0) {
        let lineData = this.playData.LineFullData.line[0];
        let imageName = this.getImageName(mapLineData.mapDataType == null ? MapDataType.ID_DRONE : mapLineData.mapDataType, mapLineData.isMain, null);
        this.playData.markerData = new MapMarkerData();
        this.playData.markerData.id = mapLineData.id;
        this.playData.markerData.mapId = MapDataType.ID_PLAYER_MARKER + "_" + mapLineData.id;
        this.playData.markerData.imageName = imageName;
        this.playData.markerData.lng = lineData.lng;
        this.playData.markerData.lat = lineData.lat;
        this.playData.markerData.mapDataType = MapDataType.ID_PLAYER_MARKER;
        this.playData.markerData.gimbalDirection = lineData.gimbalDirection;
        this.playData.markerData.rotate = lineData.rotate;
        this.playData.markerData.html = this.onGetHtmlContent(this.playData.markerData);
        this.mapInstance.onCreateMarker(this.playData.markerData);
      }

    },




    /**
     * 드론위치 이동
     * @param time 현재 vod의 시간
     * 1. 이동할 위치 찾기
     * 2. 찾은 위치로 이동경로 라인 update
     * 3. 찾은 위치로 드론 마커  update
     *
     */
    onPlayUpdatePosition(time) {
      // 라인데이터가 없는경우
      if (this.playData.LineFullData == null || this.playData.LineFullData.line === null || this.playData.LineFullData.line.length <= 0) { return; }

      // 1. 이동할 위치 찾기
      const sec = time;
      const line = this.playData.LineFullData.line;
      const first = line[0];

      var findIndex = line.findIndex((element)=> {
        const firstTime = first.time.getTime() / 1000;
        const filterTime = element.time.getTime() / 1000;
        const findTime = filterTime - firstTime;
        return sec <= findTime;
      });

      // outOfRange 처리
      if (findIndex > 0) {
        if (findIndex > line.length) {
          findIndex = line.length ;
        }
      } else {
        findIndex = 0;
      }

      // 2. 찾은 위치로 이동경로 라인 update
      this.playData.LineData.line = this.playData.LineFullData.line.slice(0,findIndex);
      if (this.playData.LineData.line.length <= 0) return
      this.mapInstance.onSetLineReplace(this.playData.LineData);

      // 3. 찾은 위치로 드론 마커  update
      let prev = findIndex - 1
      if(prev < 0) { prev = 0 }
      const currentLineData = this.playData.LineFullData.line[prev]
      const nextLineData = this.playData.LineFullData.line[findIndex]

      const currentLng = this.playData.LineFullData.line[prev].lng
      const currentLat = this.playData.LineFullData.line[prev].lat
      const nextLng = nextLineData.lng
      const nextLat = nextLineData.lat


      // 드론이동을 부드럽게 보이기 위해 보간법을 이용
      // 보간법으로 쪼갠 데이터를 담을 list 생성
      let list = null

      // 스테이션영상 => 2초간격 으로 위치데이터가 옴
      // 드론영상 => 1초간 으로 위치데이터가 옴
      // 스테이션영상일때와 일반드론영상일때를 구분하기 위한 변수
      const timeDiff = nextLineData.time.getTime() / 1000 - currentLineData.time.getTime() / 1000

      if(timeDiff !== 2) { // if 기본드론영상
        // 보간법으로 10개로 쪼갬
        list = this.MapUtils.generateIntermediatePoints(currentLat, currentLng, nextLat, nextLng, 10)
      }

      /**
       * else 스테이션 영상의 경우
       * 스테이션 영상의 경우 데이터간격이 2초임.
       * 문제는 1.5, 2.5초는 밑에 코드에서 time을 구할시 똑같은 5번째 list[5] 가르킴
       * 즉, 2.3, 2.6, 2.9 초를 이동후 3.3, 3.6, 3.9간의 위치가 같음
       *
       * -해결법-
       * 두점(P1, P2)의 중앙점(center)을 계산
       * 이동할 영상의 시간과, 현재시간의 차를 계산(next좌표의 시간 - vod플레이타임의 시간을 뻄)
       * 차이가 1 보다 클경우 쪼갤 위치의 범위구함 (P1~ center)
       * 차이가 1보다 작을경우 쪼갤 위치의 범위구함 (center ~ P2)
       *
       */
      else {
        // 현재위치와 이동해야할 위치의 중심을 구함
        const centerPoint = this.MapUtils.getMidpoint(currentLat, currentLng, nextLat, nextLng)

        // 영상 처음부터 이동해야할 좌표까지의 시간을 구함
        const nextTime = nextLineData.time.getTime() / 1000 - first.time.getTime() / 1000

        // 이동할 영상의 시간과, 현재시간의 차를 계산(next좌표의 시간 - vod플레이타임의 시간을 뻄)
        const newOverTime = nextTime - sec

        // 1초 이전
        if(newOverTime > 1) {
          list = this.MapUtils.generateIntermediatePoints(currentLat, currentLng, centerPoint.lat, centerPoint.lng, 10)
        } else {
          list = this.MapUtils.generateIntermediatePoints(centerPoint.lat, centerPoint.lng, nextLat, nextLng, 10)
        }
      }

      // 해당시간은 1.23, 1.63, 1.93 를, 2.3, 6.3, 9.3 로 만듬
      let timeIndex = (time % 1) * 10

      // timeIndex 의 남은 소숫점을 버림
      // 그러면 2, 6, 9 이런식으로 나옴
      // 해당 인덱스를 list[]에 넣으면 10개로 쪼갠것에 넣으면 순서대로 반환
      const newLat = list[Math.floor(timeIndex)].lat;
      const newLng = list[Math.floor(timeIndex)].lng;

      this.playData.markerData.lng = newLng
      this.playData.markerData.lat = newLat
      this.playData.markerData.rotate = nextLineData.rotate;
      this.playData.markerData.gimbalDirection = nextLineData.gimbalDirection;
      this.playData.markerData.html = this.onGetHtmlContent( this.playData.markerData);
      this.mapInstance.onModifyMarkerAt(this.playData.markerData);
      this.$emit('play-marker-lng-lat',this.playData.markerData.lng, this.playData.markerData.lat, findIndex)

    },




    getPlayDataLineFullDataIndex(id) {
      if (this.playData.LineFullData.length)
        return this.playData.LineFullData.findIndex(item => { return item.mapId == id })
      else
        return -1
    },
    // 영상 관련 함수 endregion


    // 맵 라인 함수 region
    /**
     * 라인 생성
     * @param mapDataType 라인 타입
     * @param data api 위치 데이터
     */
    onSetIsPolylineClick() {
      this.mapInstance.onSetIsPolylineClick();
    },

    onCreateLine(mapDataType, mapLineData) {
      let imageName = this.getImageName(mapDataType, mapLineData.isMain, null);
      mapLineData.mapId = mapDataType + "_" + mapLineData.id;
      mapLineData.imageName = imageName;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      if (mapLineData.color === null) {
        mapLineData.color = mapLineData.isMain == true ? this.redColor : this.grayColor;
      }

      if (mapDataType == MapDataType.ID_WAYPOINT || mapDataType == MapDataType.ID_MAPPING_POLYGON_LINE) {
        mapLineData.color = mapLineData.isMain == true ? this.missionLineMainColor : this.missionLineSubColor;
      }
      if (mapDataType == MapDataType.ID_DRONE && mapLineData.playerIndex != undefined) {
        mapLineData.color = this.vodLiveColor[mapLineData.playerIndex];
      }
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);
      this.mapInstance.onCreateLine(mapLineData);
    },

    onCreateLineIndex(mapDataType, mapLineData, index) {
      let imageName = this.getImageName(mapDataType, mapLineData.isMain, null);

      // mapId는 왜 있는거임??
      mapLineData.mapId = mapDataType + "_" + mapLineData.id;
      mapLineData.imageName = imageName;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      mapLineData.color = mapLineData.isMain == true ? this.redColor : this.grayColor;

      if (mapDataType == MapDataType.ID_WAYPOINT || mapDataType == MapDataType.ID_MAPPING_POLYGON_LINE) {
        mapLineData.color = mapLineData.isMain == true ? this.missionLineMainColor : this.missionLineSubColor;
      }
      if (mapDataType == MapDataType.ID_DRONE && mapLineData.playerIndex != undefined) {
        mapLineData.color = this.vodLiveColor[mapLineData.playerIndex];
      }

      // 이 라인이 뭔지 모르겠음.
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);

      this.mapInstance.onCreateLineIndex(mapLineData, index);
    },

    createLinePointDataList(data) {
      var result = []
      if (data.length > 0) {
        data.forEach(item => {
          var linePointData = new MapLinePointData();
          linePointData.index = item.index;
          linePointData.lng = item.lng;
          linePointData.lat = item.lat;
          linePointData.gimbalDirection = item.gimbalDirection;
          if (item.azimuth != undefined) {
            linePointData.rotate = item.azimuth;
          }
          if (item.time != undefined) {
            linePointData.time = new Date(item.time);
          }
          result.push(linePointData);
        });
      }
      return result;
    },
    /**
     * 라인 제거
     */
    onDeleteLineAt(mapDataType, id) {
      let mapId = mapDataType + "_" + id;
      this.mapInstance.onDeleteLineAt(mapId);
    },
    /**
     * 라인 제거
     */
    onDeleteLineListForIds(mapDataType, ids) {
      if(ids != null && ids.length > 0) {
        ids.forEach(id => {
          this.onDeleteLineAt(mapDataType, id);
        });
      }
    },
    /**
     * 라인 제거
     */
    onDeleteLineList(mapDataType) {
      let send = {
        mapId : mapDataType
      }
      this.mapInstance.onDeleteLineList(send);
    },
    /**
     * 라인 갯수
     */
    onGetLineSize(mapDataType, id) {
      let mapId = mapDataType + "_" + id;
      return this.mapInstance.onGetLineSize(mapId);
    },
    /**
     * 라인 변경
     */
    onModifyLineAddAt(mapDataType, mapLineData) {
      mapLineData.mapId = mapDataType + "_" + mapLineData.id;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);
      if (mapDataType == MapDataType.ID_DRONE && mapLineData.playerIndex != undefined) {
        mapLineData.color = this.vodLiveColor[mapLineData.playerIndex];
      }
      this.mapInstance.onSetLineAdd(mapLineData)
    },
    onModifyLineReplaceAt(mapDataType, mapLineData) {
      mapLineData.mapId = mapDataType + "_" + mapLineData.id;
      mapLineData.isShow = this.isShowLine && this.getIsShowDataType(mapDataType);
      mapLineData.line = this.createLinePointDataList(mapLineData.locationGroup);
      this.mapInstance.onSetLineReplace(mapLineData)
    },
    onLineIsSelected(mapDataType, mapLineData, isSelected) {
      mapLineData.isMain = isSelected;
      mapLineData.mapId = mapDataType + "_" + mapLineData.id;
      mapLineData.color = mapLineData.isMain == true ? this.redColor : this.grayColor;
      this.mapInstance.onModifyLineAt(mapLineData);
    },
    onIsLine(mapDataType, id) {
      let mapId = mapDataType + "_" + id;
      return this.mapInstance.onIsLine(mapId);
    },
    // 맵 관련 함수 endregion

    // 맵 라인 region
    onSetIsMarkerMove() {
      this.mapInstance.onSetIsMarkerMove();
    },
    onSetIsMarkerClick() {
      this.mapInstance.onSetIsMarkerClick();
    },
    /**
     * 맵 마커에 선택 또는 해제
     * @param dataType 영상, 사람, 드론 등 타입, 아아디 prefix 이름 설정
     * @param id 데이터 아이디
     * @param isSelected 선택 유무
     */
    onMarkerIsSelected(mapDataType, mapMarkerData, isSelected) {
      mapMarkerData.isMain = isSelected;
      var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
      mapMarkerData.mapId = mapDataType + "_" + mapMarkerData.id;
      mapMarkerData.imageName = imageName;
      mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
      mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);
      this.mapInstance.onModifyMarkerAt(mapMarkerData);
    },

    onIsMarker(mapDataType, id) {
      let mapId = mapDataType + "_" + id;
      return this.mapInstance.onIsMarker(mapId)
    },
    /**
     * 마커 생성
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param mapMarkerData MapMarkerData 형식의 데이터
     */
    onCreateMarker(mapDataType, mapMarkerData) {
      var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
      mapMarkerData.mapId = mapDataType + "_" + mapMarkerData.id;
      mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
      mapMarkerData.imageName = imageName;
      mapMarkerData.mapDataType = mapDataType;
      mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);

      this.mapInstance.onCreateMarker(mapMarkerData);
    },

    onInfoMarkerShow(mapDataType, markerData, data, event) {
      markerData.mapId = mapDataType + "_" + markerData.id;
      markerData.mapDataType = mapDataType;
      markerData.html = this.onGetMapInfoHtmlContent(mapDataType, markerData, data, event);

      if (mapDataType == MapDataType.ID_POINT) {
        // this.onPointCreateModal(false);
      }
      this.mapInstance.onInfoMarkerShow(mapDataType, markerData);
    },

    onInfoMarkerRemove(mapDataType, id) {
      this.mapInstance.onDeleteMarkerAt(mapDataType + "_" + id);
    },

    /**
     * 마커 생성
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param mapMarkerData MapMarkerData 형식의 데이터
     */
    onCreateMarkerIndex(mapDataType, mapMarkerData, index) {
      if (index != undefined) {
        var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
        mapMarkerData.mapId = mapDataType + "_" + mapMarkerData.id;
        mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
        mapMarkerData.imageName = imageName;
        mapMarkerData.mapDataType = mapDataType;
        mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);
        this.mapInstance.onCreateMarkerIndex(mapMarkerData, index);
      }
    },
    /**
     * 마커 수정
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param mapMarkerData MapMarkerData 형식의 데이터
     */
    onModifyMarkerAt(mapDataType, mapMarkerData) {
      var imageName = this.getImageName(mapDataType, mapMarkerData.isMain, mapMarkerData.pointType);
      mapMarkerData.mapId = mapDataType + "_" + mapMarkerData.id;
      mapMarkerData.imageName = imageName;
      mapMarkerData.isShow = this.getIsShowDataType(mapDataType);
      mapMarkerData.mapDataType = mapDataType;
      mapMarkerData.html = this.onGetHtmlContent(mapMarkerData);
      this.mapInstance.onModifyMarkerAt(mapMarkerData);
    },

    //이미지 데이터 구하기
    getImageName(mapDataType, isMain, pointType) {
      var result = null;
      switch (mapDataType) {
        case MapDataType.ID_POINT:
          switch (pointType) {
            case "Home":
              result = MapImageName.ID_ICON_POINT_HOME;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_HOME_SELECTED;
              // }
              break;
            case "Caution":
              result = MapImageName.ID_ICON_POINT_CAUTION;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_CAUTION_SELECTED;
              // }
              break;
            case "Danger":
              result = MapImageName.ID_ICON_POINT_DANGER;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_DANGER_SELECTED;
              // }
              break;
            case "Search":
              result = MapImageName.ID_ICON_POINT_SEARCH;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_SEARCH_SELECTED;
              // }
              break;
            case "Target":
              result = MapImageName.ID_ICON_POINT_TARGET;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_TARGET_SELECTED;
              // }
              break;
            case "Station":
              result = MapImageName.ID_ICON_POINT_STATION;
              // if(isMain != undefined && isMain == true) {
              //   result = MapImageName.ID_ICON_POINT_STATION_SELECTED;
              // }
              break;
          }
          break;
        case MapDataType.ID_DRONE:
          result = MapImageName.ID_ICON_OTHER_DRONE;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_DRONE;
          }
          if (pointType != undefined) {
            switch (pointType) {
              case "red":
                result = MapImageName.ID_ICON_DRONE_RED
                break;
              case "blue":
                result = MapImageName.ID_ICON_DRONE_BLUE
                break;
              case "orange":
                result = MapImageName.ID_ICON_DRONE_ORANGE
                break;
              case "purple":
                result = MapImageName.ID_ICON_DRONE_PURPLE
                break;
            }
          }
          break;
        case MapDataType.ID_USER:
          result = MapImageName.ID_ICON_OTHER_USER;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_USER;
          }
          break;
        case MapDataType.ID_STATION:
          result = MapImageName.ID_ICON_STATION;
          break;
        case MapDataType.ID_CONTROLLER:
          result = MapImageName.ID_ICON_OTHER_CONTROLLER;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_CONTROLLER;
          }
          break;
        case MapDataType.ID_STREAMER:
          result = MapImageName.ID_ICON_STREAMER;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_OTHER_STREAMER;
          }
          break;
        case MapDataType.ID_WAYPOINT:
          result = MapImageName.ID_ICON_WAYPOINT;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_WAYPOINT_SELECTED;
          }
          break;
        case MapDataType.ID_SITE:
          result = MapImageName.ID_ICON_SITE;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_SITE_SELECTED;
          }
          break;
        case MapDataType.ID_SITE_CIRCLE:
          result = MapImageName.ID_ICON_SITE_CIRCLE;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_SITE_SELECTED;
          }
          break;
        case MapDataType.ID_MAPPING_MARKER:
          result = MapImageName.ID_ICON_POINT_MAPPING;
          break;
        case MapDataType.ID_SCHEDULE_STATION:
          result = MapImageName.ID_SCHEDULE_STATION_GREY
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_SCHEDULE_STATION_GREEN
          }
          break
        case MapDataType.ID_MAP_AI_MARKER:
          result = MapImageName.ID_ICON_AI_MARKER;
          if (isMain != undefined && isMain == true) {
            result = MapImageName.ID_ICON_AI_MARKER;
          }
          break;
      }

      return result;
    },
    /**
     * 해당 마커 제거
     * @param mapDataType MapDataType 의 그리는 마커 종류
     * @param id 해당 아이디
     */
    onDeleteMarkerAt(mapDataType, id) {
      this.mapInstance.onDeleteMarkerAt(mapDataType + "_" + id);
    },

    onDeleteMarkerIndex(mapDataType, index) {
      this.mapInstance.onDeleteMarkerIndex(mapDataType, index);
    },

    onDeleteMarkerList(mapDataType) {
      let sendData = {
        mapId : mapDataType
      }
      this.mapInstance.onDeleteMarkerList(sendData);
    },
    onLeaveIds(mapDataType, ids) {
      if (ids.length == 0) {

        var deleteMarkerData = new MapMarkerData();
        deleteMarkerData.mapId = mapDataType;
        this.mapInstance.onDeleteMarkerList(deleteMarkerData);

        var deleteLineData = new MapLineData();
        deleteLineData.mapId = mapDataType;
        this.mapInstance.onDeleteLineList(deleteLineData);
        return;
      }

      var mapIds = []
      ids.forEach(id => {
        mapIds.push(mapDataType + "_" + id)
      })
      this.mapInstance.onLeaveIds(mapDataType, mapIds);
    },

    //맵 마커 endregion
    onDeletePolyLineList(mapDataType) {
      let sendData = {
        mapId : mapDataType
      }
      this.mapInstance.onDeletePolyLineList(sendData);
    },
    onDeletePolyLineAt(mapDataType, id) {
      let mapId = mapDataType + "_" + id;
      this.mapInstance.onDeletePolyLineAt(mapId);
    },

    onDeletePolygonList(mapDataType) {
      let sendData = {
        mapId : mapDataType
      }
      this.mapInstance.onDeletePolyLineList(sendData);
    },
    onDeletePolygonAt(mapDataType, id) {
      let mapId = mapDataType + "_" + id;
      this.mapInstance.onDeletePolygonAt(mapId);
    },

    onDeleteMappingLineList(mapDataType) {
      let sendData = {
        mapId : mapDataType
      }
      this.mapInstance.onDeleteMappingLineList(sendData);
    },
    onDeleteMappingLineAt(mapDataType, id) {
      let mapId = mapDataType + "_" + id;
      this.mapInstance.onDeleteMappingLineAt(mapId);
    },

    /**
     */
    onCreateMappingPolygon(mapPolygonData, mapDataType) {

      mapPolygonData.polygonType = mapDataType
      mapPolygonData.mapId = `${mapDataType}_${mapPolygonData.id}`


      if (this.onIsMappingPolygon(mapPolygonData.mapId)) {
        this.onUpdateMappingPolygon(mapPolygonData, mapDataType)
      } else {
        this.mapInstance.onCreatePolygon(mapPolygonData);
        this.mapInstance.setZIndexPolygon(mapPolygonData.mapId, 0);
      }
    },


    onUpdateMappingPolygon(mapPolygonData, mapDataType) {

      mapPolygonData.polygonType = mapDataType
      if (this.onIsMappingPolygon(mapPolygonData.mapId)) {
        this.mapInstance.onUpdatePolygon(mapPolygonData);
        this.mapInstance.setZIndexPolygon(mapPolygonData.mapId, 0);
      } else {
        this.onCreateMappingPolygon(mapPolygonData, mapDataType)
      }
    },
    onIsMappingPolygon(mapDataType) {
      return this.mapInstance.onIsPolygon(mapDataType)
    },

    sendMappingData(value, type) {
      this.mapInstance.setMappingData(value, type);
    },
    onCreateMappingLine(mappingType, polygonType) {
      //정사영상
      if (mappingType == 'Orthophoto') {
        this.mapInstance.onCreateMappingLine(mappingType, polygonType);
      }
    },
    //#endregion mapping 관련 함수

    //live모달
    openLiveModal() {
      this.isLiveModalOpen = true; // 모달 열기
    },
    closeLiveModal() {
      this.isLiveModalOpen = false; // 모달 닫기
    },
  },
};
</script>

<style>
img.button-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.line-show {
  width: 134px;
  height: 36px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  display: flex;
  left: 652px;
}

.line-show-select {
  width: 50%;
  height: calc(100% - 10px);
  margin: 5px;
  border-radius: 4px;
  text-align: center;
  padding-top: 8px;
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}

.line-show-select:hover {
  border-color: #505050;
}

.line-show-select.map-active {
  color: #ffffff;
  background-color: #0080FF;
}

.line-show-select.map-active:hover {
  border-color: #505050;
}

.map-type-change {
  position: absolute;
  width: 328px;
  height: 140px;
  top: 50%;
  left: 50%;
  margin: -70px 0 0 -164px;
  padding: 11px 12px 19px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
  background-color: #000;
  z-index: 10;
  pointer-events: auto;
}

.map-type-change>span {
  font-family: Noto Sans KR;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4rem;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.map-type-change>button {
  position: absolute;
  padding: 0px;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 12px;
  border: 0px;
}

.map-type-change>button>image {
  display: contents;
}

.map-type-change>img {
  position: absolute;
  width: 80px;
  height: 45px;
  top: 51px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 1px #aaaaaa;
}

.map-type-change>img.satellite {
  left: 21px;
  background-image: url(../../assets/images/map/map-type-change-satellite.png);
}

.map-type-change>img.terrain {
  left: 124px;
  background-image: url(../../assets/images/map/map-type-change-terrain.png);
}

.map-type-change>img.normal {
  left: 228px;
  background-image: url(../../assets/images/map/map-type-change-normal.png);
}

.map-type-change>img.map-type-imag-active {
  border: solid 1px #00c4ff;
}


/* .map-point-div {
  position: absolute;
  top: calc(50% + 100px);
  left: 50%;
  transform: translate(-50%, -50%);
} */

.map-point-div.google {
  top: calc(50% - 50px);
  /* 25px = 103px :map-point-create height/2 - 78 : image */
}

.map-point-modify-div {
  position: absolute;
  top: 67%;
  /* 25px = 103px :map-point-create height/2 - 78 : image */
  left: 50%;
  transform: translate(-50%, -40%);
}

.map-point-modify-div.google {
  top: calc(50% + 5px);
  /* 25px = 103px :map-point-create height/2 - 78 : image */
}

.map-point-div>div.select-image {
  text-align: center;
  padding-bottom: 10px;
}

.map-point-div>div>img.create {
  text-align: center;
  height: 51px;
}

.map-point-create {
  width: 430px;
  height: 220px;
  background: white;
  padding: 20px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
}

.lnglatData>span {
  display: inline-block;
  line-height: 1.5rem;
  font-weight: bold;
  margin-right: 20px;
}

.lnglatData>.locationInfo {
  width: calc(85% - 10px);
}

.map-point-create>div.map-point-title>span {
  font-weight: bolder;
  font-size: 1.6rem;
  font-family: NotoSansCJKkr;
  margin-top: 5px;
  margin-bottom: 15px;
}

.select-group {
  width: 100%;
}

.select-group>span {
  font-weight: bold;
  font-size: 1rem;
  margin: 5px 0px;
}

/*.map-point-create > div.select-group {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
}*/
.map-point-create>div.select-group>span {
  margin-right: 20px;
}

.map-point-create>div.select-group>div.selectCont {
  width: calc(85% - 10px);
}

.map-point-create>div.select-group>div.selectCont>div.select {
  display: inline-block;
  background-color: white;
  width: max-content;
  height: 30%;
  text-align: left;
  vertical-align: center;
  line-height: 2.6rem;
  cursor: pointer;
  margin-right: 25px;
}

.map-point-create>div.select-group>div.selectCont>div.select:nth-last-child {
  margin-right: 0;
}

.map-point-create>div.select-group>div.selectCont>div.select>span {
  font-size: 1.2rem;
}

.map-point-create>div.select-group>div.selectCont>div.active {
  color: #0080ff;
  font-weight: bold;
}

.map-point-create>div>span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 1.2rem;
}

.map-point-create>div.select-image {
  text-align: center;
  padding-top: 20px;
}

.map-point-create>div>img.create {
  text-align: center;
  height: 51px;
}

.map-point-create>div.insert-data>span {
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 2rem;
}

.map-point-create>div.insert-data>textarea {
  width: calc(80% - 10px);
  height: 53px;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 1.2rem;
  background-color: white;
  resize: none;
}

.map-point-create>div.insert-data>.btn-wrap {
  text-align: center;
  margin: 10px 0px;
}

button.medium {
  padding: 0px 30px;
}

button.medium.point.margin6 {
  margin-right: 5px;
}

.map-point-create>div.map-point-data>span {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 5px 0px;
}

.map-point-create>div.map-point-data>div.map-point-content>span {
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.map-point-create>div.map-point-data>div.map-point-content {
  display: inline-block;
  vertical-align: center;
  margin-left: 20px;
}

.mapTop {
  height: 36px;
  position: absolute;
  z-index: 1;
  padding: 9px 11px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.latLngName {
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0b7dd9;
  display: inline;
}

.latValue {
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-left: 5px;
  width: calc(45% - 21px);
  display: inline;
  ;
}

.lngValue {
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-left: 5px;
  width: calc(45% - 21px);
  display: inline;
  ;
}

.locationInfo {
  display: flex;
  justify-content: space-between;
}


.locationInfo div {
  font-size: 1.2rem;
  font-weight: normal;
}

/* .infoAlert{
  width: 300px !important;
  height: 180px;
  padding: 10px 15px;
  z-index: 1000;
  position: absolute;
  bottom: 40px;
  border-radius: 5px;
  left: 20px;
  margin: 0;
  background: rgba(16, 16, 16, 0.82);
  border: 0;
} */
.logInfo-list::-webkit-scrollbar-thumb {
  background-color: #6e6f72;
  border-radius: 4px;
}

.logInfo-list::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(40, 43, 53, 0.0);
  border: 0px solid #f0f0f0;
}

.InfoDt {
  position: relative;
  color: #ffffff;
  font-weight: normal;
  padding: 10px 0px 10px 10px;
  border-bottom: 1px solid #707070;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.InfoDt>div>span {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.InfoDt>div>img {
  width: 12px;
  margin-right: 10px;
}

.InfoDt>span:nth-child(3) {
  position: absolute;
  right: 15px;
}

/*지도 위 누적 카운트*/
.infoCount {
  width: 100% !important;
  height: auto;
  margin: 0;
  padding: 0px 15px;
  padding-bottom: 10px;
  border-radius: 5px;
  border-bottom: 1px solid #4f4f4f;
  background: rgb(35 35 35);
}

.infoCount>h2 {
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 15px;
  padding-top: 10px;
}

.allinfoCount {
  width: max-content !important;
  z-index: 1000;
  margin: 0;
  background: #101010;
  padding: 3px 10px;
  position: absolute;
  top: -5px;
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, 20px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
}

.cntIn {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.cntIn:nth-last-child {
  margin-bottom: 0px;
}

.cntIn>b {
  font-size: 1.2rem;
  line-height: 3rem;
  color: #cbcbcb;
  font-weight: 400;
  margin-left: 5px;
}

.cntIn>span {
  font-size: 1.4rem;
  color: #ffffff;
  font-weight: 400;
  line-height: 3rem;
  margin-left: 10px;
}

.cntIn>.cntIn-img {
  width: 30px;
  text-align: center;
}

.cntIn>img {
  margin: 0 5px;
}

img.gimbal-direction-title {
  top: 30px;
  left: 30px;
  position: absolute;
  display: -webkit-box;
  width: 60px;
  height: 60px;
  max-height: 60px;
}

img.gimbal-direction {
  top: -15px;
  left: -15px;
  position: absolute;
  display: -webkit-box;
  width: 60px;
  height: 60px;
  max-height: 60px;
}

#info-root>div.circle {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  text-align: center;
  vertical-align: middle;
  line-height: 25px;
  color: white;
}

#info-root>div.showName {
  width: 30px;
  height: 30px;
  transform: translate(0px, 3px);
  text-shadow: 0 0 2px #000;
  font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

#info-root>div.waypoint {
  position: absolute;
  color: #ffffff;
  z-index: 1;
  top: 0px;
  left: 5px;
}

.video-player .video-js {
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
}

@media (max-width: 1600px) {
  .modal {
    transform: translate(-50%, -55%);
  }

  .weatherWrap>.cover>img {
    margin: 10px 0 10px !important;
    height: 30px !important;
  }

  .weatherWrap>.cover>h2 {
    font-size: 1.4rem !important;
  }
}

//스테이션 CSS 추가

.station-number-mark>span {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #000;
  background: #eee;
}

.station-number-mark>span.mission-on {
  background: #0ECE4E;
  color: #fff;
}

.station-number-mark>span.rth-on {
  background: #FF4747;
  color: #fff;
}

</style>
