// info html
import icDeleteGr from "@/assets/images/icon_svg/ic_delete_gr.svg"
import icDrone from "@/assets/images/icon_svg/ic_ds_all_drone.svg"
import icMapDroneCameraAngle from "@/assets/images/map/ic_map_drone_camera_angle2.png";
import icLNBRegisterBk from "@/assets/images/icon_svg/LNB_register_bk.svg";



function convert(deg) {
    return ((deg + 360) % 360);
}


export function MapInfoHtmlEvent(data, eventCall) {
    let root = `<div id="info-root" class="box eventBox" style="z-index: 2000; width: 280px; height: 115px; padding: 10px; border-radius: 4px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);">
    <div class="boxCol2-1 flexB">
      <div class="contents" style="padding: 0; line-height: 1.8rem; max-width: 220px;">
        <p style="color:#ff5400;">No.${data.viewId} <span style="color: #919191; font-size: 1.2rem;">${data.managerDepartmentTitle}</span></p>
        <p><span class="bold">${data.title}</span></p>
        <p>${data.address}</p>
      </div>
      <button style="border: none;">
        <img id="info-icon" src="${icDeleteGr}"/>
      </button>
    </div>
    <div class="flexB">
    <div style="line-height: 36px; display: inline-flex;">
        <img id="drone-icon" src="${icDrone}"/>
        <span class="bold" style="color:#606060;">실시간 드론</span>
        <span id="drone-live-count-for-each-event" class="bold" style="color:#0790ff; margin-left: 10px">0</span>
    </div>
      <div class="buttonWrap" style="margin-top: 0">
        <button id="event-btn" class="pointLine" style="width: 3.6rem; height: 3.6rem; margin: 0; border-radius: 5px; font-size: 1.2rem;">
          선택
        </button>
      </div>
    </div>
  </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    let eventBtn = doc.getElementById("event-btn");
    let icon = doc.getElementById("info-icon");
    let liveDrone = doc.getElementById("drone-live-count-for-each-event");
    liveDrone.innerText = data.deviceCount;

    eventBtn.onmouseup = eventCall.bind(null, true);
    // if (eventBtn.addEventListener) {
    //   eventBtn.addEventListener('mouseup', eventCall.bind(null, true), true);
    // } else {
    //   eventBtn.attachEvent('on' + 'mouseup', eventCall.bind(null, true), true);
    // }

    icon.onmouseup = eventCall.bind(null, false);
    // if (icon.addEventListener) {
    //   icon.addEventListener('mouseup', eventCall.bind(null, false ), true);
    // } else {
    //   icon.attachEvent('on' + 'mouseup', eventCall.bind(null, false ), true);
    // }
    return doc.getElementById("info-root");
}

export function MapInfoHtmlToUser(data, eventCall) {

    let root = `<div id="info-root" class="box eventBox" style="z-index: 2000; width: 200px; height: 100px; padding: 10px; border-radius: 4px; box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);">
    <div class="boxCol2-1">
      <div class="contents" style="padding: 0; line-height: 1.8rem; width: 160px;">
        <p><span class="bold" style="width: 160px;">${data.tag}</span></p>
        <div class="wid5050 flexB">
          <span style="font-size: 1.2rem;">${data.departmentName}</span>
          <span style="margin-left: 10px; font-size: 1.2rem;">${data.userName}</span>
        </div>
      </div>
      <div id="eventBtn" class="buttonWrap" style="margin-top: 0">
        <img id="infoIcon" alt="icon"/>
      </div>
    </div>
    <div style="margin-top: 5px; margin-left: 32px;">
      <p>
        <span class="lnglatData">위도</span>
        <span class="lnglatVal">${data.lat}</span>
      </p>
      <p>
        <span class="lnglatData">경도</span>
        <span class="lnglatVal">${data.lng}</span>
      </p>
    </div>
  </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    var target = doc.getElementById("eventBtn");
    var icon = doc.getElementById("infoIcon");
    icon.src = icDeleteGr
    if (target.addEventListener) {
        target.addEventListener('mouseup', eventCall, true);
    } else {
        target.attachEvent('on' + 'mouseup', eventCall, true);
    }
    return doc.getElementById("info-root");
}

export function MapInfoHtmlToDroneDetail(data, eventCall, vue) {
    eventCall;
    vue;
    let root = `<div id="info-root" style="z-index: 500; text-align: center; ">
          <div style="position: relative; display: flex; flex-wrap: wrap; flex-direction: column;">
            <div class="arrow_box eventBox" style="position: sticky; width: 260px; height: 110px; padding: 10px; margin-bottom: 15px; border-radius: 4px; box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5);">
              <div class="arrow_box_top">
                <div class="flexB">
                  <span id="info-name" class="bold" >${data.name}</span>
                  <img id="info-delete-icon" src="${icDeleteGr}" alt="icon"/>
                </div>
                <div class="flex">
                  <span id="info-user-department-name">${data.userDepartmentName}</span>
                  <span id="info-user-name">${data.userName}</span>
                </div>
              </div>
              <div style="background: #F5F5F5; border-radius: 3px; margin: 5px 0;">
                <div class="arrow_box_bottom">
                  <div class="flex">
                    <span class="lnglatData">고도</span>
                    <span id="info-height" class="lnglatVal">${data.distance == undefined ? 0 : data.height}m</span>
                  </div>
                  <div class="flex">
                    <span class="lnglatData">거리</span>
                    <span id="info-distance" class="lnglatVal">${data.distance == undefined ? 0 : data.distance}m</span>
                  </div>
                </div>
                <div class="arrow_box_bottom" >
                  <div class="flex">
                    <span class="lnglatData">위도</span>
                    <span id="info-lng" class="lnglatVal">${(data.lat == undefined || data.lat == null) ? 0.0 : data.lat.toFixed(9)}</span>
                  </div>
                  <div class="flex">
                    <span class="lnglatData">경도</span>
                    <span id="info-lat" class="lnglatVal">${(data.lng == undefined || data.lng == null) ? 0.0 : data.lng.toFixed(9)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    let infoClose = doc.getElementById("info-delete-icon");
    infoClose.onmouseup = eventCall.bind(null, data, false);

    return doc.getElementById("info-root");
}

export function MapInfoHtmlToPointModify(data, eventCall, vue) {
    let placeholder = vue.$t('point-register-textarea-placeholder-text');
    let root = `<div id="info-root" class="map-point-create" style="height:210px;">
          <div class="map-point-title" style="display:flex; justify-content: space-between;">
            <span>중요위치점 수정</span>
            <button style="border: none; line-height: 36px;">
              <img id="infoIcon"/>
            </button>
          </div>
          <div class="flex lnglatData">
            <span>위치좌표</span>
            <div class="locationInfo">
              <div class="latLngName">위도</div>
              <div class="latValue">${data.lat != null ? data.lat.toFixed(9) : '-'}</div>
              <div class="latLngName">경도</div>
              <div class="lngValue">${data.lng != null ? data.lng.toFixed(9) : '-'}</div>
            </div>

          </div>
          <div class="map-point-data">
            <span>구분선택</span>
            <div class="map-point-content">
              <span class="bold" style="color: #0790ff;"> ${data.typeName} </span>
            </div>
          </div>
          <div class="insert-data">
            <span>참고사항</span>
            <textarea id="pointData" @keyup="lengthLimit"  maxlength="201"  placeholder="${placeholder}">
            </textarea>
            <div id="buttonGroup" class="btn-wrap">
              <button id="pointModifySave" class="medium point" style="margin-right: 16px;">
                확인
              </button>
              <button id="pointModifyCancel" class="medium"">
                취소
              </button>
            </div>
          </div>
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    var infoRoot = doc.getElementById("infoRoot");
    var buttonGroup = doc.getElementById("buttonGroup");
    var pointModifySave = doc.getElementById("pointModifySave");
    var pointModifyCancel = doc.getElementById("pointModifyCancel");
    var pointData = doc.getElementById("pointData");
    var icon = doc.getElementById("infoIcon");
    icon.src = icDeleteGr

    if (!data.isOwner) {
        pointData.setAttribute("disabled", "disabled")
        buttonGroup.style = "display:none;";
        infoRoot.style = "height: 190px";
    }
    pointData.innerText = data.memo == null ? "" : data.memo;

    pointData.oninput = (event) => {
        data.memo = event.target.value;
    }

    pointModifySave.onmouseup = eventCall.bind(null, data, true);
    pointModifyCancel.onmouseup = eventCall.bind(null, data, false);

    icon.onmouseup = eventCall.bind(null, data, false);
    return doc.getElementById("info-root");
}

export function MapInfoHtmlToPointDetail(data, eventCall, vue) {
    let placeholder = vue.$t('point-register-textarea-placeholder-text');
    eventCall;
    let root = `<div id="info-root" style="z-index: 2000;">
          <div style="position: relative; display: flex; flex-wrap: wrap; flex-direction: column;">
            <div class="arrow_box" style="position:sticky; text-align: center; width: 320px; height: 210px; padding: 20px; margin-bottom: 10px; border-radius: 4px; box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.5);">
              <div class="arrow_box_top">
                <div class="flexB" style="margin-bottom:10px;">
                  <div class="flex">
                    <h1 id="info-name" class="tooltipData">${data.name}</h1>
                    <h3>
                      <span id="info-user-department-name">${data.userDepartmentName}</span>│
                      <span id="info-user-name">${data.userName}</span>
                    </h3>
                  </div>
                  <img id="info-delete-icon" src="${icDeleteGr}" alt="icon"  style="width:15px;"/>
                </div>
                <textarea id="info-point-data-change-textarea" style="font-size:1.3rem; height: 45px; width: 100%; margin: 0px; resize: none;"  disabled="disabled" @keyup="lengthLimit"  maxlength="201"  placeholder="${placeholder}">
                </textarea>
              </div>
              <div class="arrow_box_bottom" style="border-radius: 3px; margin: 10px 0;display: flex;flex-direction: column;}">
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin-bottom:8px;">
                  <span class="lnglatData" style="width: 50px;border-radius: 10px;background: #eee;color: #969696;">위도</span>
                  <span class="lnglatVal">${(data.lat == undefined || data.lat == null) ? 0.0 : data.lat.toFixed(9)}</span>
                </div>
                <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
                  <span class="lnglatData" style="width: 50px;border-radius: 10px;background: #eee;color: #969696;">경도</span>
                  <span class="lnglatVal">${(data.lng == undefined || data.lng == null) ? 0.0 : data.lng.toFixed(9)}</span>
                </div>
              </div>
              <div style="width: 100%;display: flex;align-items: center;justify-content: space-between;margin-top: 15px;">
                <img id="info-point-data-change-btn" src="${icLNBRegisterBk}" data-is-modify="false" style="margin-right: 3px; cursor: pointer; display: ${data.isOwner ? 'block' : 'none'};"/>
                <button id="info-point-data-change-save-btn" style="display: none; margin:0; padding: 0 10px; height: 25px; font-size: 1.2rem; font-weight: 400;border:0;color: #fff;border-radius: 5px;background: rgb(0, 128, 255);" > 수정완료 </button>
              </div>
            </div>
          </div>
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    let infoPointDataChangeBtn = doc.getElementById("info-point-data-change-btn");
    let infoPointDataChangeSaveBtn = doc.getElementById("info-point-data-change-save-btn");
    let infoPointDataChangeTextarea = doc.getElementById("info-point-data-change-textarea");

    let infoClose = doc.getElementById("info-delete-icon");

    infoPointDataChangeTextarea.innerText = data.memo == null ? "" : data.memo;
    infoPointDataChangeTextarea.oninput = (event) => {
        data.memo = event.target.value;
    }

    infoPointDataChangeBtn.onmouseup = () => {
        let isModify = infoPointDataChangeBtn.dataset.isModify;
        let getModify = isModify == "true";
        infoPointDataChangeBtn.dataset.isModify = !getModify;
        getModify = !getModify;
        if (getModify) {
            infoPointDataChangeSaveBtn.style.display = "block";
            infoPointDataChangeTextarea.removeAttribute("disabled");
        } else {
            infoPointDataChangeSaveBtn.style.display = "none";
            infoPointDataChangeTextarea.setAttribute("disabled", "disabled");
        }
    }

    if (data.editMode) {
        infoPointDataChangeBtn.dataset.isModify = data.editMode;
        infoPointDataChangeSaveBtn.style.display = "block";
        infoPointDataChangeTextarea.removeAttribute("disabled");
    }

    infoClose.onmouseup = eventCall.bind(null, data, false);
    infoPointDataChangeSaveBtn.onmouseup = eventCall.bind(null, data, true);
    return doc.getElementById("info-root");
}


// info custom marker
export function MapCustomMarkerEventCircle(data, eventCall) {
    eventCall;
    let root = `<div id="info-root" style="z-index: 100; text-align: center;">
  <!-- 드론 아이콘 -->
  <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
</div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerEventCenter(data, eventCall) {
    eventCall;
    let root = `<div id="info-root" style="z-index: 100; text-align: center;">
  <!-- 드론 아이콘 -->
        <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
    </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerUser(data, eventCall) {
    eventCall;
    let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

    if (mapCanvas == null) {
        return null;
    }

    let root = `<div id="info-root" class="map-icon-s" style="z-index: 100; text-align: center;">
          <div class="tooltipBack" style="background-size:120px 30px; width: 120px; height: 25px; padding: 5px 15px; margin-bottom: 3px;">
            <p id="info-name" class="bold">${data.name}</p>
          </div>
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" />
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerStreamer(data, eventCall) {
    eventCall;
    let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);
    if (mapCanvas == null) {
        return null;
    }

    let root = `<div id="info-root" style="z-index: 100; text-align: center;">
          <div class="tooltipBack" style="background-size:120px 30px; width: 120px; height: 25px; padding: 5px 15px; margin-bottom: 3px;">
            <p id="info-name" class="bold">${data.name}</p>
          </div>
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" style="width:30px;"/>
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerDeviceDrone(data, eventCall) {
    eventCall;
    let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

    if (mapCanvas == null) {
        return null;
    }

    let root = `<div data-map-id="${data.mapId}" id="info-root" style="z-index: 500; text-align: center; width: 30px; height: 30px;">
          <div style="position: absolute; z-index: 300; left: -45px; top: -45px;">
              <div class="tooltipBack" style="  background-size:145px 50px; width: 145px; height: 45px; padding: 5px 15px; margin-bottom: 3px;">
                  <p id="info-name" class="bold">${data.name}</p>
                  <div class="flexB">
                    <span class="lnglatData bold" style="font-size: 1.2rem; color: #50b1ff;">${data.userName.length > 3 ? data.userName.substr(0, 3) + "..." : data.userName}</span>
                    <span class="lnglatData bold" style="font-size: 1.2rem; color: #808080;" alt="${data.userDepartmentName}">${data.userDepartmentName.length > 5 ? data.userDepartmentName.substr(0, 5) + "..." : data.userDepartmentName}</span>
                  </div>
                </div>
          </div>

          <!-- 드론 아이콘 -->
          <div>
            <img id="${data.mapId}-gimbal-direction-img" class="gimbal-direction" style="visibility: hidden;" src="${icMapDroneCameraAngle}" alt="icon" />
            <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon"/>
          </div>
        </div>`;
    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    let img = doc.getElementById(data.mapId + "-info-img");
    let gimbalDirectionImg = doc.getElementById(data.mapId + "-gimbal-direction-img");
    let rotate = convert(Number(data.rotate));
    img.style.transform = "rotate(" + rotate + "deg)";
    if (data.gimbalDirection != null) {
        let gimbalDirection = convert(Number(data.gimbalDirection));
        let gimbal = rotate + gimbalDirection;
        gimbalDirectionImg.style.transform = "rotate(" + convert(gimbal) + "deg)";
        gimbalDirectionImg.style.visibility = "visible"
    }

    return doc.getElementById("info-root");
}

export function MapCustomMarkerDeviceDroneDetail(data, eventCall) {
    eventCall;

    let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

    if (mapCanvas == null) {
        return null;
    }

    let root = `<div id="info-root" style="z-index: 500; text-align: center; width: 30px; height: 30px;">
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-gimbal-direction-img" class="gimbal-direction" style="visibility: hidden;" src="${icMapDroneCameraAngle}" alt="icon" />
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" />
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    let img = doc.getElementById(data.mapId + "-info-img");
    let gimbalDirectionImg = doc.getElementById(data.mapId + "-gimbal-direction-img");
    let rotate = convert(Number(data.rotate));
    img.style.transform = "rotate(" + rotate + "deg)";
    if (data.gimbalDirection != null) {
        let gimbalDirection = convert(Number(data.gimbalDirection));
        let gimbal = rotate + gimbalDirection;
        gimbalDirectionImg.style.transform = "rotate(" + convert(gimbal) + "deg)";
        gimbalDirectionImg.style.visibility = "visible"
    }

    return doc.getElementById("info-root");
}

export function MapCustomMarkerPlayerDrone(data, eventCall) {
    eventCall;
    let mapCanvas = document.querySelector(`canvas[data-map-img-id="${data.imageName}"]`);

    if (mapCanvas == null) {
        return null;
    }

    let root = `<div id="info-root" style="z-index: 500; text-align: center; width: 30px; height: 30px;">
          <!-- 드론 아이콘 -->
          <img id="${data.mapId}-gimbal-direction-img" class="gimbal-direction" style="visibility: hidden;" src="${icMapDroneCameraAngle}" alt="icon" />
          <img id="${data.mapId}-info-img" src="${mapCanvas.toDataURL('image/svg+xml')}" alt="icon" style="${data.imageName == 'ID_ICON_STREAMER' ? 'width:30px;' : ''}" />
        </div>`;
    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    let img = doc.getElementById(data.mapId + "-info-img");
    let gimbalDirectionImg = doc.getElementById(data.mapId + "-gimbal-direction-img");
    let rotate = convert(Number(data.rotate));
    img.style.transform = "rotate(" + rotate + "deg)";
    if (data.gimbalDirection != null) {
        let gimbalDirection = convert(Number(data.gimbalDirection));
        let gimbal = rotate + gimbalDirection;
        gimbalDirectionImg.style.transform = "rotate(" + gimbal + "deg)";
        gimbalDirectionImg.style.visibility = "visible"
    }

    return doc.getElementById("info-root");
}

export function MapCustomMarkerPoint(data, eventCall) {
    eventCall;
    let root = `<div id="info-root" style="text-align: center;">
          <div class="tooltipBack" style="background-size: 70px 25px; width: 70px; height: 24px; line-height: 23px;">
            <p class="bold" style="color:#ff8800;">${data.name}</p>
          </div>
          <!-- 포인트 아이콘 -->
          <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerPointDetail(data, eventCall) {
    eventCall;
    let root = `<div id="info-root" style="text-align: center;">
          <div class="tooltipBack" style="visibility: hidden; background-size: 70px 25px; width: 70px; height: 24px; line-height: 23px;">
            <p class="bold" >${data.name}</p>
          </div>
          <!-- 포인트 아이콘 -->
          <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");

    return doc.getElementById("info-root");
}

export function MapCustomMarkerCircle(data) {
    let root = `<div id="info-root">
    <img id="${data.mapId}-info-img" style="transform: rotate(0deg);">
    <div id="info-name" class="circle" style="background-color:${data.color};">${data.name}</div>
  </div>`

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerWaypoint(data) {
    let root = `<div id="info-root">
    <div id="info-name" class="showName waypoint">${data.name}</div>
    <img id="${data.mapId}-info-img" src="${data.icon}" style="transform: rotate(0deg);">
  </div>`

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerMapping(data) {
    let root = `<div id="info-root">
    <div id="info-name" class="showName waypoint">${data.name == null ? '' : data.name}</div>
    <img  src="${data.icon}" style="transform: rotate(0deg); width: 20px; height: 20px;">
  </div>`

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

export function MapCustomMarkerAI(data) {
    let root = `<div id="info-root">
    <div id="info-name" class="showName waypoint" style="background:#fff;width:60px;height:25px;border-radius:3px;border:1px solid #0080FF;">
      <span style="font-size:1.2rem;color: #0080FF;text-shadow:0 0 0px;">${data.name == null ? '' : data.name}</span>
    </div>
  </div>`

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");

}

/**
 * @param data: MapMarkerData
 */
export function MapCustomMarkerStation(data) {
    let root = `<div id="info-root" class="map-icon-s" style="z-index: 100; text-align: center;">
          <!-- 스테이션 아이콘 -->
          <img id="${data.mapId}-info-img" src="${data.icon}" alt="icon" />
        </div>`;

    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}


export function MapCustomMarkerStationNumber(data) {
    let root = `<div id="info-root" class="map-icon-s" style="z-index: 100; text-align: center;">
          <!-- 스테이션 아이콘 -->
          <div class="station-number-mark">
           <span class="${data.isRTL ? 'rth-on' : data.isMain ? 'mission-on' : ''} " >${data.name }</span>
          </div>
        </div>`;
    let parser = new DOMParser();
    let doc = parser.parseFromString(root, "text/html");
    return doc.getElementById("info-root");
}

